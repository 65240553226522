import { Card, CardContent, Button } from "@mui/material";
import Box from "components/common/Box";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import CustomDataGrid from "components/datagrid/CustomGrid";
// import { modelImportTableRows } from "helpers/constants";
import { useEffect, useState } from "react";
import { tradesApiAction } from "stores/redux/apiSlices/trades/tradesApiSlice";
import { styled } from "@mui/system";

// icon
import KeyboardBackspaceSharpIcon from "@mui/icons-material/KeyboardBackspaceSharp";

//
import _get from "lodash/get";
import NewDialog from "components/common/Dialog";
import TextField from "components/common/TextField";

const StyledButton = styled(Button)(({ theme }) => ({
  fontWeight: 600,
  borderRadius: "8px",
  fontSize: "14px",
  padding: "10px 28px",
  backgroundColor: "#219653",
  color: "#fff",
  "&:hover": {
    color: "#fff",
    backgroundColor: "#142E56",
  },
  "&:not(:hover)": {
    color: "#fff",
  },
}));

const ModelImportHeader = ({
  handleSendCalls,
  data,
  trade,
  selectedRows = [],
  selectedStockNameNumber,
  setFetchedData,
  parseAddSingleTradeResponse,
  handleStackCallWithApiResponse,
  result,
  TradeID,
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [addSingleTrade] = tradesApiAction.addTradesApi();
  if (!data || data.length === 0 || !selectedStockNameNumber) {
    return null; // Return null if required props are not available
  }

  const counts = data.reduce((acc, item) => {
    if (selectedRows.includes(item.id)) {
      acc[item.risk_profile_name] = (acc[item.risk_profile_name] || 0) + 1;
    }
    return acc;
  }, {});

  // const handleButtonClick = () => {
  //   const tradeId = TradeID; // Get trade_id from data
  //   if (tradeId) {
  //     handleStackCallWithApiResponse(tradeId); // Pass tradeId to the function
  //   } else {
  //     console.error("Trade ID is missing or invalid!");
  //   }
  // };

  const handleButtonClick = () => {
    handleSendCalls(); // Call your function
    setIsButtonDisabled(true); //
  };

  return (
    <Box
      sx={{
        backgroundColor: "#E6FFE3",
        m: 2,
        height: "78px",
        padding: "7px 20px",
        border: "1px solid #219653",
        borderRadius: "8px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mt: "12px",
        }}
      >
        <Box>
          <Text fontSize="16px" fontWeight="400" color="#676C76" mb={2}>
            {selectedStockNameNumber} customers for {data[0].symbol} stocks have
            been selected for the trade
          </Text>
          <Text sx={{ fontSize: "16px", fontWeight: "500", color: "#242424" }}>
            {counts["Aggressive"] || 0} aggressive & {counts["Moderate"] || 0}{" "}
            moderate risk profile & {counts["Conservative"] || 0} Conservative
            customers have been selected for the trade
          </Text>
        </Box>
        <Box sx={{ mt: 1 }}>
          <StyledButton
            onClick={handleButtonClick} // Pass tradeId here
            disabled={isButtonDisabled}
          >
            {result?.customers?.[0]?.is_manual_trade == 1
              ? "Create Data"
              : "Send Call"}
          </StyledButton>
        </Box>
      </Box>
    </Box>
  );
};

const SelectCustomerSendCallsTable = (props) => {
  const {
    customerColumns,
    onRowSelectionModelChange,
    data,
    selectedRows,
    handleSendCalls,
    handleStackCall,
    StackList,
    backtoMainScreen,
    productId, // Product id
    mode, // IMPORT, SINGLE
    isEditTradeDrawerOpen,
    onEditIsTradeDrawerOpen,
    setFetchedData,
    open,
    handleCloseModal,
    selectedRow,
    setEditableQuantity,
    editablePrice,
    setEditablePrice,
    ordertypeStack,
    setOrderTypeStack,
    editableStopLoss,
    setEditableStopLoss,
    editableProductType,
    setEditableProductType,
    handleCloseModalUpdate,
    editableQuantity,
    parseAddSingleTradeResponse,
    result,
    trade_id,
  } = props;

  const TradeID = trade_id;

  const [StackTradeId, setStackTradeId] = useState();
  const [expandedTradeId, setExpandedTradeId] = useState(null);
  const [tradesPaginationModel, setTradesPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [tableData, setTableData] = useState(data);
  const [isCustomGridVisible, setIsCustomGridVisible] = useState(false);

  const [getTradeDetailsUsingId, { data: tradeTableData }] =
    tradesApiAction.getTradeDetailsUsingId();

  const [deleteTradeCalls] = tradesApiAction.deleteTrade();
  // const [getTradeTableUsingId, { data: tradeTableData, error }] = tradesApiAction.getTradeUsingId(); // fetch data to display details
  const [getTradeTableUsingId] = tradesApiAction.getTradeUsingId(); // fetch Data to update send call

  const [updatePendingTrade] = tradesApiAction.updatePendingApi();

  const [getTradeTableUsingIdd] = tradesApiAction.getTradeUsingIds();

  const selectedStockNameNumber = selectedRows?.length;
  // const tradeData = StackList?.result?.filter((trade) => trade.flag === 0);

  const onViewDetail = async (tradeId) => {
    if (expandedTradeId === tradeId) {
      setExpandedTradeId((prevTradeId) =>
        prevTradeId === tradeId ? null : tradeId
      );
      setIsCustomGridVisible(false); // Hide the custom grid
      return;
    }

    const res = await getTradeTableUsingId({
      productId,
      StackTradeId: tradeId,
    });
    if (res && res.data && res.data.result) {
      onRowSelectionModelChange([]);
      updateTableListData(res.data.result);
    }

    setStackTradeId(tradeId);
    setExpandedTradeId((prevTradeId) =>
      prevTradeId === tradeId ? null : tradeId
    );
    setIsCustomGridVisible(true); // Show the custom grid
  };

  const onEditDetail = async (tradeId) => {
    if (!isCustomGridVisible) {
      alert("Please view the details first.");
      return;
    }

    const res = await getTradeTableUsingIdd({
      productId,
      StackTradeId: tradeId,
    });

    if (res && res.data && res.data.result) {
      onRowSelectionModelChange([]);
      updateTableListData(res.data.result);
      setFetchedData(res.data.result);
    }

    setStackTradeId(tradeId);
    onEditIsTradeDrawerOpen({ tradeId: tradeId });
  };

  //---------------------------> Stack Actions handle
  // useEffect(() => {
  //   if (StackTradeId) {
  //      getTradeTableUsingId({StackTradeId, productId});
  //   }
  // }, []);
  useEffect(() => {
    if (data) {
      setTableData(data); // Ensure table updates when parent prop 'data' changes
    }
  }, [data]);

  // update table listing data.
  const updateTableListData = (data = []) => {
    if (data && data?.length) {
      const id = data.map((d) => d.customer_id);

      onRowSelectionModelChange(id);
      setTableData(data);
    }
  };

  const tradeDataForTable = tradeTableData?.result || [];

  const tradeDataForTableWithId = tradeDataForTable.map((item, index) => ({
    ...item,
    id: index + 1,
  }));

  //delete stack trade for green stack

  const handleDeleteTrade = async (tradeDataForTableWithId) => {
    const payload = {
      tradeIds: tradeDataForTableWithId.customers.map((customer) => ({
        customer_id: customer,
        trade_id: tradeDataForTableWithId.trade_id,
      })),
    };

    try {
      // Call deleteTrade API
      const response = await deleteTradeCalls(payload);

      if (response) {
        // Refresh the page after successful deletion
        backtoMainScreen();
      }
    } catch (error) {
      console.error("Error deleting trade:", error);
    }
  };

  const handleStackCallWithApiResponse = async (tradeId) => {
    // setStackTradeId(tradeId);
    try {
      const res = await getTradeTableUsingId({
        productId,
        StackTradeId: tradeId,
      });
      let tradeData = _get(res, "data.result", []);

      // check if send calls & view details ID are same.
      if (expandedTradeId === tradeId) {
        if (selectedRows && selectedRows.length) {
          tradeData = getTableDataWithId(tradeData);
          tradeData = tradeData?.filter((d) => selectedRows.includes(d.id));
        } else {
          return;
        }
      }

      if (tradeData && tradeData?.length) {
        handleStackCall(tradeId, tradeData);
      }
    } catch (error) {
      console.error("Error fetching trade details:", error);
    }
  };

  const getTableDataWithId = (data = []) => {
    const tableDataWithId = data.map((item, index) => ({
      ...item,
      id: item?.customer_id,
    }));
    return tableDataWithId;
  };

  const StackCallList =
    StackList?.result?.filter((trade) => trade.flag === 0) || [];
  const tableDataWithId = getTableDataWithId(tableData);

  const handleSave = () => {
    if (selectedRow) {
      // Find the index of the row to be updated
      const updatedTableData = tableData.map((row) => {
        if (row.customer_id === selectedRow.customer_id) {
          // Update the row with the new values
          return {
            ...row,
            quantity: editableQuantity,
            product_type: editableProductType,
            order_type: ordertypeStack,
            price: editablePrice,
            stop_loss: editableStopLoss,
          };
        }
        return row;
      });

      // Update the table data state
      setTableData(updatedTableData);

      // Optionally, close the modal after saving
      handleCloseModal();
    }
  };

  //---------------------------> Stack Actions handle
  return (
    <>
      <NewDialog open={open} onClose={handleCloseModal}>
        <Box
        // sx={{
        //   p: 4,
        //   backgroundColor: "white",
        //   width: "400px",
        //   margin: "auto",
        //   marginTop: "100px",
        // }}
        >
          <h2>Edit Trade</h2>
          {selectedRow && (
            <div>
              {/* Display static fields */}
              <p style={{ marginTop: "12px", marginBottom: "20px" }}></p>
              <TextField
                select
                label="Order Type"
                value={ordertypeStack}
                onChange={(e) => setOrderTypeStack(e.target.value)} // Update orderType on change
                fullWidth
                sx={{ marginBottom: 2 }}
                SelectProps={{
                  native: true, // Using native select for simplicity
                }}
              >
                <option value="MARKET">MARKET</option>
                <option value="LIMIT">LIMIT</option>
              </TextField>

              <TextField
                label="Price"
                variant="outlined"
                value={editablePrice}
                onChange={(e) => {
                  const value = e.target.value;
                  // Allow numbers with optional decimal point
                  if (/^\d*\.?\d*$/.test(value)) {
                    setEditablePrice(value);
                  }
                }}
                fullWidth
                sx={{ marginBottom: 2 }}
                disabled={ordertypeStack !== "LIMIT"}
              />

              <TextField
                label="Stop Loss"
                variant="outlined"
                value={editableStopLoss}
                onChange={(e) => {
                  const value = e.target.value;
                  // Only allow numbers
                  if (/^\d*\.?\d*$/.test(value)) {
                    setEditableStopLoss(value);
                  }
                }}
                fullWidth
                sx={{ marginBottom: 2 }}
              />

              <TextField
                select
                label="Product Type"
                value={editableProductType}
                onChange={(e) => setEditableProductType(e.target.value)} // Update orderType on change
                fullWidth
                sx={{ marginBottom: 2 }}
                SelectProps={{
                  native: true, // Using native select for simplicity
                }}
              >
                <option value="CNC">CNC</option>
                <option value="MIS">MIS/Margin</option> {/* Updated value */}
                <option value="NRML">NORMAL</option>
              </TextField>

              {/* Editable quantity */}
              <TextField
                label="Quantity"
                variant="outlined"
                value={editableQuantity}
                onChange={(e) => {
                  const value = e.target.value;
                  // Only allow numbers
                  if (/^\d*$/.test(value)) {
                    setEditableQuantity(value);
                  }
                }}
                fullWidth
                sx={{ marginBottom: 2 }}
              />

              {/* Add more fields as necessary */}
            </div>
          )}
          <Button variant="contained" onClick={handleCloseModal} sx={{ mr: 2 }}>
            Close
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </NewDialog>
      <Box>
        {StackCallList && StackCallList.length ? (
          <>
            {/* back to main page. */}
            <Box
              sx={{
                marginTop: "10px",
                cursor: "pointer",
                position: "sticky",
                top: "0px",
                backgroundColor: "#ffffff80",
                zIndex: "9",
                display: "inline-block",
              }}
            >
              <KeyboardBackspaceSharpIcon onClick={backtoMainScreen} />
            </Box>
            <Stack>
              {StackCallList.map((trade) => (
                <div key={trade.trade_id}>
                  <Card
                    key={trade.trade_id}
                    style={{ marginBottom: "10px", marginTop: "10px" }}
                  >
                    <CardContent
                      sx={{
                        backgroundColor: "#E6FFE3",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        border: "1px solid #219653",
                        borderRadius: "8px",
                        padding: "9px 16px !important",
                      }}
                    >
                      <Text variant="body2" component="h2" sx={{ flex: 1 }}>
                        Stock: {trade.symbol}
                      </Text>
                      <Text
                        variant="body2"
                        color="textSecondary"
                        sx={{ flex: 1 }}
                      >
                        Total Customers: {trade.total_customer}
                      </Text>

                      <Box
                        sx={{
                          flex: 2,
                          display: "flex",
                          justifyContent: "end",
                          gap: "40px",
                        }}
                      >
                        {/* <Button
                          variant="contained"
                          color="primary"
                          sx={{ textTransform: "unset", width: "143px" }}
                          onClick={() => handleDeleteTrade(trade)}
                          disabled={
                            !isCustomGridVisible ||
                            expandedTradeId !== trade.trade_id
                          }
                        >
                          Delete
                        </Button>

                        <Button
                          onClick={() => onEditDetail(trade.trade_id)}
                          variant="contained"
                          color="primary"
                          sx={{ textTransform: "unset", width: "143px" }}
                          disabled={
                            !isCustomGridVisible ||
                            expandedTradeId !== trade.trade_id
                          }
                        >
                          Edit Detail
                        </Button> */}

                        <Button
                          onClick={() => onViewDetail(trade.trade_id)}
                          variant="contained"
                          color="primary"
                          sx={{ textTransform: "unset", width: "143px" }}
                        >
                          {expandedTradeId === trade.trade_id
                            ? "Close"
                            : "View Detail"}
                        </Button>

                        {expandedTradeId === trade.trade_id && (
                          <>
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{ textTransform: "unset", width: "143px" }}
                              onClick={() => handleDeleteTrade(trade)}
                            >
                              Delete
                            </Button>

                            <Button
                              onClick={() => onEditDetail(trade.trade_id)}
                              variant="contained"
                              color="primary"
                              sx={{ textTransform: "unset", width: "143px" }}
                            >
                              Edit Detail
                            </Button>
                          </>
                        )}

                        <Button
                          onClick={() =>
                            handleStackCallWithApiResponse(trade.trade_id)
                          }
                          variant="contained"
                          sx={{
                            backgroundColor: "#219653",
                            textTransform: "unset",
                          }}
                          disabled={
                            expandedTradeId === trade.trade_id &&
                            !selectedRows?.length &&
                            isButtonDisabled
                          }
                        >
                          Send Calls
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>

                  {expandedTradeId === trade.trade_id &&
                    tableDataWithId &&
                    tableDataWithId.length && (
                      <CustomDataGrid
                        autoHeight
                        list={tableDataWithId}
                        columnHeaderHeight={46}
                        rowHeight={60}
                        columns={customerColumns}
                        checkboxSelection
                        onRowSelectionModelChange={onRowSelectionModelChange}
                        selectedRows={selectedRows}
                        pagination
                        rowCount={tableDataWithId?.length || 0}
                        paginationMode="client"
                        paginationModel={tradesPaginationModel}
                        onPaginationModelChange={setTradesPaginationModel}
                      />
                    )}
                </div>
              ))}
            </Stack>
          </>
        ) : null}

        {/* While adding Data */}
        {data && customerColumns && (
          <>
            <Card sx={{ mt: "15px" }}>
              <Stack>
                <ModelImportHeader
                  handleSendCalls={handleSendCalls}
                  data={data}
                  selectedRows={selectedRows}
                  selectedStockNameNumber={selectedStockNameNumber}
                  result={result}
                  handleStackCallWithApiResponse={
                    handleStackCallWithApiResponse
                  }
                  TradeID={TradeID}
                />

                <CustomDataGrid
                  autoHeight
                  list={data}
                  columnHeaderHeight={46}
                  rowHeight={60}
                  columns={customerColumns}
                  checkboxSelection
                  onRowSelectionModelChange={onRowSelectionModelChange}
                  selectedRows={selectedRows}
                  // pagination
                  pagination
                  rowCount={data?.length || 0}
                  paginationMode="client" // client, server
                  paginationModel={tradesPaginationModel}
                  onPaginationModelChange={setTradesPaginationModel}
                />
              </Stack>
            </Card>
          </>
        )}
      </Box>
    </>
  );
};

export default SelectCustomerSendCallsTable;
