import { Box } from "@mui/material";
import CustomDataGrid from "components/datagrid/CustomGrid";

const Position = ({ PostionTableRows = [], PositionTableColoums = [], positionpage, setPositionPage }) => {
 const Row = PostionTableRows?.data?.parsedData
 const count =  PostionTableRows?.data?.positionsCount

 const handlePaginationChange = (newPaginationModel) => {  
  setPositionPage({
    ...positionpage,
    page: newPaginationModel.page,
    pageSize: newPaginationModel.pageSize,
  });
};
  return (
    

    <Box sx={{ width: '100%' }}>
      <CustomDataGrid
        list={Row}
        columns={PositionTableColoums}
        columnHeaderHeight={46}
        rowHeight={46}
 
        pagination
        paginationModel={positionpage}
        paginationMode="server"
        onPaginationModelChange={handlePaginationChange}
        rowCount={count}
      />
    </Box>

  );
};
export default Position;
