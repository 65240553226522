import React, { useState, useEffect } from "react";
import SideDrawer from "components/common/SideDrawer";
import Stack from "components/common/Stack";
import Button from "components/common/Button";
import Box from "components/common/Box";
import FilterListIcon from "@mui/icons-material/FilterList";
import Text from "components/common/Text";
import Checkbox from "components/common/Checkbox";
import NewTextField from "components/common/TextField";
import { customersApiAction } from "stores/redux/apiSlices/customers/customersApiSlice";
import { useDispatch } from "react-redux";
import { toastActions } from "stores/redux/slices/toastSlice";
import { columnGroupsStateInitializer } from "@mui/x-data-grid/internals";

// const options = [
//   { label: "Model-portfolio", name: "sendToModelPortfolio" },
//   { label: "Custom", name: "sendToCustom" },
//   { label: "Algo", name: "sendToAlgo" },
// ];

const AddFilter = (props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [validationMessage, setValidationMessage] = useState(""); // Step 1: State for validation message

  const getfilterData = customersApiAction.getFilterData();
  const filterDataField = getfilterData?.data ?? [];

  const { postData, setPostData, applyBtn , getClearFilter} = props;

  // const handleCheckboxChange = (type, id) => {
  //   setPostData((prevData) => {
  //     let updatedData;

  //     if (prevData[type].includes(id)) {
  //       updatedData = {
  //         ...prevData,
  //         [type]: prevData[type].filter((item) => item !== id),
  //       };
  //     } else {
  //       updatedData = {
  //         ...prevData,
  //         [type]: [...prevData[type], id],
  //       };
  //     }

  //     return updatedData;
  //   });
  // };

  // below function is real
  // const handleCheckboxChange = (type, id) => {
  //   setPostData((prevData) => {
  //     let updatedData;

  //     if (type === "investment_type" || type === "risk_profile") {
  //       let updatedArray;

  //       if (prevData[type].includes(id.toString())) {
  //         // If the ID exists in the array, remove it
  //         const index = prevData[type].indexOf(id.toString());

  //         if (index === 0) {
  //           updatedArray = prevData[type].replace(`${id},`, "");
  //         }
  //         if (index > 0) {
  //           updatedArray = prevData[type].replace(`${id}`, "");
  //         }
  //         // updatedArray = prevData[type].slice(0, index) + prevData[type].slice(index + 1);
  //       } else {
  //         // If the ID doesn't exist in the array, add it
  //         updatedArray = [...prevData[type], id];
  //         updatedData = {
  //           ...prevData,
  //           [type]: updatedArray.join(","), // Convert array to comma-separated string
  //         };
  //       }
  //     } else {
  //       if (prevData[type].includes(id)) {
  //         updatedData = {
  //           ...prevData,
  //           [type]: prevData[type].filter((item) => item !== id),
  //         };
  //       } else {
  //         updatedData = {
  //           ...prevData,
  //           [type]: [...prevData[type], id],
  //         };
  //       }
  //     }

  //     return updatedData;
  //   });
  // };
  const handleCheckboxChange = (type, id) => {
    setPostData((prevData) => {
      // if (type === "investment_type" || type === "risk_profile") {
      //   const currentString = prevData[type] || "";

      //   // Toggle the presence of the ID in the string
      //   const updatedString = currentString.includes(id)
      //     ? currentString
      //         .replace(new RegExp(`(?:^|,)${id}(?:,|$)`, "g"), ",")
      //         .trim() // Replace extra commas around the ID
      //     : `${currentString} ${id}`.trim();

      //   return {
      //     ...prevData,
      //     [type]: updatedString.replace(/\s+/g, ","), // Replace spaces with commas
      //   };
      // } else {
        // For other types, toggle the presence of the ID in the array
        const updatedData = {
          ...prevData,
          [type]: prevData[type]?.includes(id)
            ? prevData[type].filter((item) => item !== id)
            : [...(prevData[type] || []), id],
        };

        return updatedData;
      // }
    });
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;

    if (value === "") {
      setPostData((prevData) => ({
        ...prevData,
        [field]: "",
      }));
      return;
    }

    if (!/^\d+$/.test(value)) {
      return;
    }
    setPostData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const saveDataToServer = () => {
    if (parseInt(postData.capital_from) > parseInt(postData.capital_to)) {
      setValidationMessage(
        "Please adjust the Capital range from Minimum to Maximum."
      );
      return;
    }
    applyBtn();
    closeDrawer(false);
  };
  const openDrawer = () => {
    setIsDrawerOpen(true);
  };
  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };
  const handleClearButton = () => {
    resetState();
    getClearFilter();
    setIsDrawerOpen(!isDrawerOpen);
  };

  const resetState = () => {
    setPostData({
      search: "",
      capital_from: "",
      capital_to: "",
      investment_type: "",
      plan_status: "",
      risk_profile: "",
      riskFilter_selected:'',
      customer_category : '' ,
      skip: "0",
      take: "10",
    });
  };


  return (
    <div style={{ marginTop: "14px" }}>
      <Button
        sx={{ fontWeight: 600, borderRadius: "8px", p: 1, width: "130px" }}
        onClick={openDrawer}
        startIcon={<FilterListIcon />}
        variant="outlined"
      >
        Add Filter
      </Button>
      {isDrawerOpen && (
        <SideDrawer
          anchor="right"
          open={isDrawerOpen}
          closeDrawer={closeDrawer}
          title="Add Filter"
          subtitle="See the data in an organized manner by applying filters."
          handleSubmit={saveDataToServer}
          cancelButtonText="Clear All"
          submitButtonText="Apply"
          primaryAction={() => handleClearButton()}
        >
          <Text variant="small" component="h4" fontSize="16px" fontWeight="500">
            Capital range
          </Text>
          <Stack direction="row" spacing={1}>
            <NewTextField
              placeholder="Eg. from ₹ 10,00,000"
              name="capital_from"
              value={postData.capital_from}
              onChange={(e) => handleInputChange(e, "capital_from")}
            />
            <Text
              style={{ marginTop: "12px", fontSize: "14px", fontWeight: "500" }}
            >
              to
            </Text>
            <NewTextField
              placeholder="Eg. to ₹ 50,00,000"
              name="capital_to"
              value={postData.capital_to}
              onChange={(e) => handleInputChange(e, "capital_to")}
            />
          </Stack>
          <Box>
            {validationMessage && (
              <Text style={{ color: "red", fontSize: "15px" }}>
                {validationMessage}
              </Text>
            )}
          </Box>
          <Box sx={{}}>
            <Text
              variant="small"
              component="h4"
              fontSize="16px"
              fontWeight="500"
            >
              Investment type
            </Text>
            <Stack
              direction="row"
              justifyContent="space-between"
              style={{ marginLeft: "15px",marginTop:'-10px' }}
            >
              {filterDataField?.result?.investFilter?.map((investData) => (
                <Checkbox
                  key={investData.product_id}
                  label={
                    <Text
                      variant="small"
                      fontWeight="400"
                      fontSize="14px"
                      style={{ marginLeft: "10px" }}
                    >
                      {investData.product_name}
                    </Text>
                  }
                  size="small"
                  name="investmentType"
                  checked={postData.investment_type.includes(
                    investData.product_id
                  )}
                  onChange={() =>
                    handleCheckboxChange(
                      "investment_type",
                      investData.product_id
                    )
                  }
                />
              ))}
            </Stack>
          </Box>

          <Box sx={{}}>
            <Text
              variant="small"
              component="h4"
              fontSize="16px"
              fontWeight="500"
            >
              Plan status
            </Text>
            <Stack
              direction="row"
              justifyContent="space-between"
              style={{ marginLeft: "15px",marginTop:'-10px' }}
            >
              {filterDataField?.result?.planFilter &&
                Object.entries(filterDataField?.result?.planFilter).map(
                  ([planId, planLabel]) => (
                    <Checkbox
                      key={planLabel}
                      label={
                        <Text
                          variant="small"
                          fontWeight="400"
                          fontSize="14px"
                          style={{ marginLeft: "10px" }}
                        >
                          {planLabel}
                        </Text>
                      }
                      size="small"
                      name={`sendToModelPortfolio-${planLabel}`}
                      checked={postData.plan_status.includes(planLabel)}
                      onChange={() =>
                        handleCheckboxChange("plan_status", planLabel)
                      }
                    />
                  )
                )}
            </Stack>
          </Box>

          <Box>
            <Text
              variant="small"
              component="h4"
              fontSize="16px"
              fontWeight="500"
            >
            Selected Risk Profile
            </Text>
            <Stack
              direction="row"
              justifyContent="space-between"
              style={{  marginLeft: "15px" , marginTop:'-10px' }}
            >
              {filterDataField?.result?.riskFilter_selected?.map((riskFilter) => (
                <Checkbox
                  key={riskFilter?.risk_profile_id}
                  label={
                    <Text
                      variant="small"
                      fontWeight="400"
                      fontSize="14px"
                      style={{ marginLeft: "10px" }}
                    >
                      {riskFilter?.name}
                    </Text>
                  }
                  size="small"
                  name="selectedRiskProfile"
                  checked={postData?.riskFilter_selected?.includes(
                    riskFilter.risk_profile_id
                  )}
                  onChange={() =>
                    handleCheckboxChange(
                      "riskFilter_selected",
                      riskFilter.risk_profile_id
                    )
                  }
                />
              ))}
            </Stack>
          </Box>

          <Box>
            <Text
              variant="small"
              component="h4"
              fontSize="16px"
              fontWeight="500"
            >
               Assessed Risk Profile
            </Text>
            <Stack
              direction="row"
              justifyContent="space-between"
              style={{ marginLeft: "15px" , marginTop:'-10px' }}
            >
              {filterDataField?.result?.riskFilter?.map((riskFilter) => (
                <Checkbox
                  key={riskFilter.risk_profile_id}
                  label={
                    <Text
                      variant="small"
                      fontWeight="400"
                      fontSize="14px"
                      style={{ marginLeft: "10px" }}
                    >
                      {riskFilter.name}
                    </Text>
                  }
                  size="small"
                  name="assessedRiskProfile"
                  checked={postData?.risk_profile?.includes(
                    riskFilter?.risk_profile_id
                  )}
                  onChange={() =>
                    handleCheckboxChange(
                      "risk_profile",
                      riskFilter?.risk_profile_id
                    )
                  }
                />
              ))}
            </Stack>
          </Box>

          <Box>
            <Text
              variant="small"
              component="h4"
              fontSize="16px"
              fontWeight="500"
            >
             Customer Category
            </Text>
            <Stack
              direction="row"
              justifyContent="space-evenly"
              style={{ marginLeft: "-93px" , marginTop:'-10px' }}
            >
                 {filterDataField?.result?.customer_category?.map((category) => (
                <Checkbox
                key={category.customer_category}
                  label={
                    <Text
                      variant="small"
                      fontWeight="400"
                      fontSize="14px"
                      style={{ marginLeft: "10px" }}
                    >
                      {category.customer_category}
                    </Text>
                  }
                  size="small"
                  name="sendToModelPortfolio"
                  checked={postData?.customer_category?.includes(
                    category?.customer_category
                  )}
                  onChange={() =>
                    handleCheckboxChange(
                      "customer_category",
                      category?.customer_category
                    )
                  }
                />
              ))}
            </Stack>
          </Box>
        </SideDrawer>
      )}
    </div>
  );
};

export default AddFilter;
