import React, { useEffect, useState } from "react";
import Button from "components/common/Button";
import Card from "components/common/Card";
import Text from "components/common/Text";
import Box from "components/common/Box";
import Divider from "components/common/Divider";
import Grid from "components/common/Grid";
import TextField from "components/common/TextField";
import FileUpload from "components/common/FileUpload";
import { profileApiAction } from "stores/redux/riaProfile/riaProfileSlice";
import { toastActions } from "stores/redux/slices/toastSlice";
import { useDispatch, useSelector } from "react-redux";
import { fileToBase64 } from "helpers/fileFormat";
import DownloadButton from "components/common/DownloadButton";
import Stack from "components/common/Stack";
import styled from "@emotion/styled";
import IconButton from "components/common/IconButton";
import { validationHelper } from "helpers/validation";
import EditIcon from "@mui/icons-material/Edit";
import { uuid } from "helpers/utility";
import DatePicker from "components/common/DatePicker";
import dayjs from "dayjs";
import { clickAndDownloadFile } from "helpers/fileFormat";
import OnOffButton from "components/addCustomer/OnOffButton";
import Dialog from "components/common/Dialog";
import Checkbox from "components/common/Checkbox";

const HeaderBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "16px 24px",
}));

const CancelButton = styled(Button)(() => ({
  border: "1px solid #142E56",
  borderRadius: "4px",
  color: "#142E56",
  textTransform: "capitalize",
  width: "116px",
  padding: "8px 16px",
  marginRight: "24px",
}));

const SaveButton = styled(Button)(() => ({
  border: "1px solid #142E56",
  borderRadius: "4px",
  color: "#FFF",
  textTransform: "capitalize",
  width: "116px",
  padding: "8px 16px",
}));
const EditButton = styled(Button)(() => ({
  border: "1px solid #828282",
  borderRadius: "4px",
  color: "#101828",
  textTransform: "capitalize",
}));
const AvatarBox = styled(Box)(() => ({
  width: "236px",
  height: "236px",
  borderRadius: "9px",
  backgroundColor: "#E5EEFF",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const LabelText = styled(Text)(() => ({
  fontSize: "16px",
  fontWeight: 400,
  color: "#667085",
  width: "250px",
  textTransform: "uppercase",
}));
const EditTextField = styled(TextField)(() => ({
  display: "flex",
  width: "300px",
  p: "4px 16px",
  alignItems: "center",
  borderRadius: "1px solid #CBCBCB",
}));

const ValueText = styled(Text)(() => ({
  fontSize: "16px",
  color: "#101828",
  fontWeight: 500,
  flexShrink: 0,
}));

const DocumentText = styled(Text)(() => ({
  color: "#242424",
  fontSize: "16px",
  fontWeight: 500,
  textTransform: "capitalize",
  paddingTop: "24px",
}));
const DocumentNameText = styled(Text)(() => ({
  color: "#242424",
  fontSize: "14px",
  fontWeight: 500,
  textTransform: "capitalize",
  paddingTop: "24px",
}));
const UploadDocText = styled(Text)(() => ({
  color: "#242424",
  fontSize: "14px",
  fontWeight: 500,
  textTransform: "capitalize",
  paddingTop: "24px",
}));

const AddDocumentButton = styled(Button)(() => ({
  fontsize: "14px",
  fontweight: 600,
  marginLeft: "40px",
  color: "#142E56",
  textTransform: "capitalize",
  marginBottom: "32px",
  marginTop: "16px",
}));

const getUserDetails = (profileData) => {
  const userDetails = [
    { label: "Email", key: "email", value: profileData?.email },
    { label: "Phone", key: "phone", value: profileData?.mobile },
    { label: "User Type", key: "userType", value: profileData?.user_type },
  ];

  if (profileData.user_type == "SubBroker") {
    userDetails.push(
      {
        label: "Aadhar Number",
        key: "aadharCard",
        value: profileData?.aadhar_card,
      },
      { label: "Pan Number", key: "panCard", value: profileData?.pan_card },
      {
        label: "Nism Number",
        key: "nismNumber",
        value: profileData?.nism_number,
      },
      {
        label: "Algo",
        key: "algo",
        value: profileData?.algo,
      }
    );

    // Conditionally add employee details only if 'aadhar_card' exists
    if (profileData?.employeeDetails?.aadhar_card) {
      userDetails.push(
        {
          label: "Emp Nism Number",
          key: "empNismNo",
          value: profileData?.employeeDetails?.nism_number,
        },
        {
          label: "Emp Pan No",
          key: "empPanNo",
          value: profileData?.employeeDetails?.pan_card,
        },
        {
          label: "Emp Aadhar No",
          key: "empAadharNo",
          value: profileData?.employeeDetails?.aadhar_card,
        }
      );
    }
  } else {
    userDetails.push(
      {
        label: "Joining Date",
        key: "joiningDate",
        value: dayjs(profileData?.joining_date).format("DD-MM-YYYY"),
      },
      {
        label: "SEBI Registration Number",
        key: "sebiNumber",
        value: profileData?.sebi_reg_no,
      },
      {
        label: "SEBI Expiry",
        key: "sebiExpiry",
        value: profileData?.sebi_expiry,
      },
      {
        label: "Portal Expiry",
        key: "portalExpiry",
        value: profileData?.portal_expiry_date,
      },

      { label: "BASL Number", key: "baslNumber", value: profileData?.basl_no },
      {
        label: "BASL Expiry",
        key: "baslExpiry",
        value: profileData?.basl_expiry,
      }
    );
  }

  return userDetails;
};

const ProfileCard = () => {
  const [editMode, setEditMode] = useState(false);
  const [editedValues, setEditedValues] = useState({});
  const [userDocuments, setUserDocuments] = useState([]);
  const [updatedValues, setUpdatedValues] = useState({});
  const [userDetails, setUserDetails] = useState([]);
  const [additionalStocks, setAdditionalStocks] = useState(4);
  const [isDownload, setIsDownload] = useState(false);
  const [exDividendDate, setExDividendDate] = useState(null);

  const [editText, setEditText] = useState(true);
  const [previousIsChecked, setPreviousIsChecked] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isChecked, setIsChecked] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    nism: null,
  });
  const [formError, setFormError] = useState({
    nism: null,
  });

  const [newDocForm, setNewDocForm] = useState({
    display_name: null,
    file: null,
  });
  const [newDocError, setNewDocError] = useState({
    display_name: null,
    file: null,
  });
  const [isDocFormOpen, setIsDocFormOpen] = useState(false);

  const dispatch = useDispatch();

  const [getProfileData, { data: profileData }] =
    profileApiAction.getProfileApi();
  console.log("profileData++", profileData);

  const [fetchProfileDoc, { data: profileDoc = {} }] =
    profileApiAction.getLazyProfileDocApi();
  const [handleUpdateProfileApi] = profileApiAction.updateProfileApi();
  const [handleUpdateFile] = profileApiAction.updateDocApi();

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    xyz();
  }, []);

  useEffect(() => {
    if (profileData) {
      const isAlgoTradeEnabled = profileData.isAlgoSubscribed == 1;
      setIsChecked(isAlgoTradeEnabled);
      setPreviousIsChecked(isAlgoTradeEnabled);
    }
  }, [profileData]);

  useEffect(() => {
    if (profileData) {
      const userDocs = getUserDoc(profileData.documents);
      setUserDocuments(userDocs);
      const details = getUserDetails(profileData);
      setUserDetails(details);
    }
  }, [profileData]);

  useEffect(() => {
    getProfileData();
  }, []);

  const getUserDoc = (docs) => {
    const docData = docs.map((item) => {
      return {
        document_id: item.document_id,
        userDocId: item.userDocId,
        display_name: item.display_name,
        fileName: item.fileName,
        isEdit: false,
        id: uuid(),
      };
    });
    return docData;
  };

  const handleEditClick = (rowData) => {
    setEditMode(true);
    const initialEditedValues = {};
    userDetails.forEach((detail) => {
      if (detail.key === "baslExpiry") {
        initialEditedValues[detail.key] = dayjs(detail.value) ?? null;
      } else {
        initialEditedValues[detail.key] = detail.value;
      }
    });
    setEditedValues(initialEditedValues);
  };

  const handleChange = () => {
    if (!isChecked) {
      setModalOpen(true);
      setIsChecked((prev) => !prev);
      setPreviousIsChecked(isChecked);
    } else {
      setIsChecked((prev) => !prev);
    }
  };

  const handleCancelClick = () => {
    setEditMode(false);

    setFormData({
      nism: null, // Clear the uploaded file
    });
    setFormError({
      nism: null, // Clear any errors related to the file upload
    });

    setModalOpen(false);
    setIsCheckboxChecked(false);
    setUpdatedValues({});
  };

  const handleCancel = () => {
    setIsChecked((prevChecked) => !prevChecked);
    setIsCheckboxChecked(false);
    setModalOpen(false);
    close();

    // Clear form data
    setFormData({});

    // Clear file input
    // if (fileInputRef.current) {
    //   fileInputRef.current.value = null;
    // }
  };

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked((prevChecked) => !prevChecked);
  };

  const handleValidation = () => {
    let isValid = true;
    let errors = {};

    // Only validate the aadharCard field if isAutoTradeDocumentUpload is true

    if (!profileData.isAutoTradeDocumentUpload && !formData.nism) {
      if (!formData.nism) {
        isValid = false;
        errors.nism = "This field is required";
      }
    }

    setFormError(errors);
    return isValid;
  };

  const handleSaveClick = () => {
    const payload = {
      name: editedValues.name || profileData?.name,
      email: editedValues.email || profileData?.email,
      phone: editedValues.phone || profileData?.mobile,
      user_type: editedValues.userType || profileData?.user_type,
      sebi_reg_no: editedValues.sebiNumber || profileData?.sebi_reg_no,
      basl_no: editedValues.baslNumber || profileData?.basl_no,
      basl_expiry: editedValues.baslExpiry || profileData?.basl_expiry,
      nism_number: editedValues.nismNumber || profileData?.nism_number,
      sebi_expiry: editedValues.sebiExpiry || profileData?.sebi_expiry,
      portal_expiry_date:
        editedValues.portalExpiry || profileData?.portal_expiry_date,
      isAlgoSubscribed: isChecked ? 1 : 0,
    };

    handleUpdateProfileApi(payload)
      .unwrap()
      .then((response) => {
        console.log({ response });
        if (response) {
          const toastMessage =
            response.status === 1
              ? response.message
              : "RIA Profile updated succesfully ";

          dispatch(
            toastActions.setToastData({
              message: toastMessage,
              variant: response.status === 1 ? "success" : "error",
            })
          );
        }

        if (response.status === 1) {
          getProfileData();
          setIsDocFormOpen(false);
          setNewDocForm({});
        }
      })
      .catch((error) => {
        console.error("Error in API call:", error);
      });
    setEditMode(false);
  };

  const downloadFile = async (document_id) => {
    const payload = {
      documentId: document_id,
    };

    try {
      let res = await fetchProfileDoc(payload, false);
      if (res.data.file) {
        const { file, filename } = res.data;
        await clickAndDownloadFile({ base64File: file, fileName: filename });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputChange = (key, value) => {
    setEditedValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
    setUpdatedValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleDocEdit = (id) => {
    let newDoc = [...userDocuments];
    newDoc.forEach((item) => {
      if (item.id === id) {
        if (item.isEdit) {
          delete item.updatedFile;
        }
        item.isEdit = !item.isEdit;
      }
    });
    setUserDocuments(newDoc);
  };

  const handleChange1 = ({ name, value }) => {
    let newFormData = { ...formData, [name]: value };
    setFormData(newFormData);
  };

  const handleDocDataChange = ({ name, value, id }) => {
    let newUserDocs = [...userDocuments];
    newUserDocs.forEach((item) => {
      if (item.id === id) {
        item[name] = value;
      }
    });
    setUserDocuments(newUserDocs);
  };

  const submitDocUpdate = async (docData) => {
    if (!docData?.updatedFile) {
      let newUserDocs = [...userDocuments];
      newUserDocs.forEach((item) => {
        if (item.id === docData.id) {
          item.isEdit = false;
        }
      });
      setUserDocuments(newUserDocs);
      return;
    }
    let baseFile = await fileToBase64(docData.updatedFile);
    let payload = {
      document_id: docData.document_id,
      userDocId: docData.userDocId,
      display_name: docData.display_name,
      file: `data:application/pdf;base64,${baseFile}`,
    };
    try {
      const res = await handleUpdateFile(payload);
      // update wala toast msg
      if (res) {
        const toastMessage =
          res.data.status === 1
            ? res.data.message
            : "RIA Document updated successfully";

        dispatch(
          toastActions.setToastData({
            message: toastMessage,
            variant: res.data.status === 1 ? "success" : "error",
          })
        );
      }

      if (res.data.status == 1) {
        getProfileData();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleNewFormDataChange = ({ name, value }) => {
    let newDocData = { ...newDocForm };
    newDocData[name] = value;
    setNewDocForm(newDocData);
  };

  const handleNewDocSubmitValidation = () => {
    const newDocFormError = { ...newDocError };

    const nameValidation = validationHelper.required(newDocForm.display_name);
    newDocFormError.display_name = nameValidation.message;

    const fileValidation = validationHelper.required(newDocForm.file);
    newDocFormError.file = fileValidation.message;

    setNewDocError(newDocFormError);
    return nameValidation.isValid && fileValidation.isValid;
  };

  const handelNewFormSubmit = async () => {
    if (!handleNewDocSubmitValidation()) {
      return;
    }

    let baseFile = await fileToBase64(newDocForm?.file);
    const payload = {
      document_id: 0,
      userDocId: 0,
      display_name: newDocForm.display_name,
      file: `data:application/pdf;base64,${baseFile}`,
    };
    // api call
    try {
      const res = await handleUpdateFile(payload);
      if (res) {
        const toastMessage =
          res.data.status === 1
            ? res.data.message
            : "RIA Document uploaded successfully";

        dispatch(
          toastActions.setToastData({
            message: toastMessage,
            variant: res.data.status === 1 ? "success" : "error",
          })
        );
      }
      if (res.data.status == 1) {
        getProfileData();
        setIsDocFormOpen(false);
        setNewDocForm({});
      }
    } catch (err) {
      console.log(err);
    }
  };

  const xyz = () => {
    if (profileData && profileData.isAlgoSubscribed) {
      if (profileData.isAlgoSubscribed == 0) {
        setIsChecked(false);
      } else if (profileData.isAlgoSubscribed == 1) {
        setIsChecked(true);
      }
    }
  };

  const handleButtonClick = async (event) => {
    event.preventDefault();

    if (!handleValidation()) {
      return;
    }

    setIsCheckboxChecked(false);
    setModalOpen(false);

    let nismString = "";

    if (formData.nism) {
      const getBase64String = async (file) => {
        if (Array.isArray(file)) {
          file = file[0];
        }
        const base64 = await fileToBase64(file);
        let fileType = file.type;

        if (fileType === "image/jpeg") {
          fileType = "application/jpeg";
        }

        if (fileType === "image/png") {
          fileType = "application/png";
        }

        return { base64, fileType };
      };

      const { base64, fileType } = await getBase64String(formData.nism);
      nismString = `data:${fileType};base64,${base64}`;
    }

    const payload1 = {
      name: editedValues.name || profileData?.name,
      email: editedValues.email || profileData?.email,
      phone: editedValues.phone || profileData?.mobile,
      user_type: editedValues.userType || profileData?.user_type,
      sebi_reg_no: editedValues.sebiNumber || profileData?.sebi_reg_no,
      basl_no: editedValues.baslNumber || profileData?.basl_no,
      basl_expiry: dayjs(editedValues.baslExpiry).format("YYYY-MM-DD"),
      nism_certificate_vii_file: nismString || "",
      role_id: editedValues.roleId || profileData?.role_id,
      isAlgoSubscribed: isChecked ? 1 : 0,
    };

    try {
      const res1 = await handleUpdateProfileApi(payload1);
      console.log("res1", res1);
    } catch (err) {
      console.log(err);
    }
  };
  const avatarText = profileData?.name
    .split(" ")
    .map((word) => word[0])
    .join("");
  return (
    <>
      <Box>
        <Card sx={{ marginTop: "25px" }}>
          <HeaderBox>
            <Box>
              <Text
                sx={{ color: "#101828", fontSize: "20px", fontWeight: 500 }}
              >
                {profileData?.name}
              </Text>
            </Box>

            <Box>
              {editMode ? (
                <>
                  <CancelButton variant="outlined" onClick={handleCancelClick}>
                    Cancel
                  </CancelButton>
                  <SaveButton variant="contained" onClick={handleSaveClick}>
                    Save
                  </SaveButton>
                </>
              ) : (
                <EditButton
                  variant="outlined"
                  onClick={() => {
                    handleEditClick();
                  }}
                >
                  Edit Details
                </EditButton>
              )}
            </Box>
          </HeaderBox>

          <Divider />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              padding: "32px 40px",
            }}
          >
            <AvatarBox>
              <Text
                sx={{ fontSize: "64px", fontWeight: 600, color: "#104960" }}
              >
                {avatarText}
              </Text>
            </AvatarBox>

            <Box sx={{ marginLeft: 8 }}>
              {userDetails?.map((detail, index) => {
                if (detail.key === "algo") {
                  return (
                    <Stack
                      direction="row"
                      alignItems="center"
                      key={index}
                      sx={{
                        mb: 2,
                      }}
                    >
                      <LabelText>{detail.label}</LabelText>

                      {editMode ? (
                        <OnOffButton
                          checked={isChecked}
                          onChange={handleChange}
                          opacity={profileData?.nism_number === "" ? 0.5 : 1} // Adjust opacity when disabled
                          cursor={
                            profileData?.nism_number === ""
                              ? "not-allowed"
                              : "pointer"
                          } // Disable pointer when NISM number is empty
                          onText="Enabled"
                          offText="Disabled"
                          disabled={profileData?.nism_number === ""} // Disable the button if NISM number is empty
                        />
                      ) : (
                        <OnOffButton
                          checked={isChecked}
                          opacity={1}
                          cursor="unset"
                          onText="Enabled"
                          offText="Disabled"
                        />
                      )}
                      {/* <Link
                          onClick={downloadAgreementTemplate}
                          href="#"
                          underline="always"
                          sx={{
                            color: "#0C53B4",
                            fontSize: "14px",
                            fontWeight: "500px",
                            ml: 2,
                          }}
                        >
                          {"Download Agreement"}
                        </Link> */}
                      <Dialog
                        open={modalOpen}
                        onClose={() => setModalOpen(false)}
                        disableCloseIcon
                        maxWidth="sm"
                        max
                        sx={{ borderRadius: "10px" }}
                        enableBackdropClick={false}
                        title="Algo "
                        contentComponent={() => (
                          <>
                            <Box sx={{ display: "flex" }}>
                              <Checkbox
                                sx={{ mt: "2px" }}
                                checked={isCheckboxChecked}
                                onChange={handleCheckboxChange}
                              />
                              <Text
                                sx={{
                                  color: "#242424",
                                  fontSize: "16px",
                                  fontWeight: 500,
                                  mb: 2,
                                  mt: 2,
                                }}
                              >
                                Are you sure you want to enable the algo
                                setting?
                              </Text>
                            </Box>

                            <>
                              <FileUpload
                                name="nism"
                                handleChange={handleChange1}
                                acceptedFileTypes={[
                                  "application/pdf",
                                  // "image/jpeg",
                                  // "image/jpg",
                                  // "image/png",
                                ]}
                              />
                              {formError.nism && (
                                <Text variant="small" color="red" py={1}>
                                  {formError.nism}
                                </Text>
                              )}
                            </>
                          </>
                        )}
                        actionComponent={(close) => (
                          <>
                            <Box mb={2}>
                              <Button
                                onClick={handleCancel}
                                disableEndIcon={false}
                                variant="outlined"
                                size="large"
                                m={0.5}
                                sx={{
                                  borderRadius: "8px",
                                  border: "1px solid #142E56",
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  width: "46%",
                                  ml: 2,
                                  mr: 2,

                                  textTransform: "capitalize",
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                onClick={handleButtonClick}
                                disableEndIcon={false}
                                variant="outlined"
                                size="large"
                                m={0.5}
                                sx={{
                                  borderRadius: "8px",
                                  border: "1px solid #142E56",
                                  // backgroundColor: "#142E56",
                                  // color: "#FFFFFF",
                                  backgroundColor: isCheckboxChecked
                                    ? "#142E56"
                                    : "#828282",
                                  color: isCheckboxChecked
                                    ? "#FFFFFF"
                                    : "#666666",
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  width: "46%",
                                  "&:hover": { backgroundColor: "#142E56" },
                                  textTransform: "capitalize",
                                }}
                                disabled={!isCheckboxChecked}
                              >
                                Confirm
                              </Button>
                            </Box>
                          </>
                        )}
                      />
                    </Stack>
                  );
                }
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <LabelText>{detail.label}:</LabelText>
                    {editMode &&
                    detail.key !== "joiningDate" &&
                    detail.key !== "userType" ? (
                      detail.key === "baslExpiry" ||
                      detail.key === "sebiExpiry" ||
                      detail.key == "portalExpiry" ? (
                        <DatePicker
                          sx={{ width: "300px" }}
                          onChange={(date) =>
                            handleInputChange(detail.key, date)
                          }
                          value={editedValues[detail.key]}
                        />
                      ) : (
                        <EditTextField
                          placeholder={detail.label}
                          size="small"
                          defaultValue={
                            detail.key === "baslExpiry" ||
                            detail.key === "sebiExpiry" ||
                            detail.key === "portalExpiry"
                              ? dayjs(detail.value).format("DD-MM-YYYY")
                              : editedValues[detail.key]
                          }
                          onChange={(e) =>
                            handleInputChange(detail.key, e.target.value)
                          }
                        />
                      )
                    ) : (
                      <ValueText>
                        {detail.key === "baslExpiry" ||
                        detail.key === "sebiExpiry" ||
                        detail.key === "portalExpiry"
                          ? dayjs(detail.value).format("DD-MM-YYYY")
                          : detail.value}
                      </ValueText>
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>

          <Box sx={{ padding: "32px 40px" }}>
            <Divider />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DocumentText>Documents </DocumentText>
              </Grid>

              {userDocuments?.map((item, index) => (
                <React.Fragment key={index}>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <DocumentNameText>Document name</DocumentNameText>
                      <TextField
                        // onChange={handleDocDataChange}
                        onChange={(e) =>
                          handleDocDataChange({
                            id: item.id,
                            value: e.target.value,
                            name: "display_name",
                          })
                        }
                        value={item.display_name}
                        sx={{ marginTop: "6px" }}
                        disabled
                      />
                    </Grid>
                    {item.isEdit ? (
                      <Grid item xs={5}>
                        <UploadDocText>Upload Document</UploadDocText>
                        <FileUpload
                          handleChange={({ name, value }) => {
                            handleDocDataChange({
                              name: "updatedFile",
                              value: value[0],
                              id: item.id,
                            });
                          }}
                          acceptedFileTypes={[
                            "application/pdf",
                            "image/jpeg",
                            "image/png",
                          ]}
                        ></FileUpload>
                      </Grid>
                    ) : (
                      <Grid item xs={5}>
                        <Stack
                          sx={{ width: "100%", height: "100%" }}
                          direction="row"
                          alignItems="flex-end"
                        >
                          <DownloadButton
                            handleDownload={() => {
                              setIsDownload(true);
                              downloadFile(item.userDocId);
                            }}
                          >
                            {item.fileName}
                          </DownloadButton>
                        </Stack>
                      </Grid>
                    )}
                    <Grid xs={2} spacing={2}>
                      <Stack
                        sx={{ width: "100%", height: "100%" }}
                        direction="row"
                        alignItems="flex-end"
                      >
                        {item.isEdit ? (
                          <Stack
                            direction="row"
                            ml={1}
                            mb={
                              newDocError?.file && newDocError?.display_name
                                ? 5.2
                                : 0.8
                            }
                            spacing={1}
                          >
                            <Button
                              size="large"
                              onClick={() => {
                                submitDocUpdate(item);
                                setEditText(true);
                              }}
                            >
                              Save
                            </Button>
                            <Button
                              size="large"
                              onClick={() => {
                                handleDocEdit(item.id);
                                setEditText(true);
                              }}
                            >
                              Cancel
                            </Button>
                          </Stack>
                        ) : (
                          <Box mx={1}>
                            <Button
                              variant="outlined"
                              size="medium"
                              startIcon={<EditIcon />}
                              sx={{ height: "51px" }}
                              onClick={() => {
                                handleDocEdit(item.id);
                                setEditText(false);
                              }}
                            >
                              Edit
                            </Button>
                          </Box>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
              {isDocFormOpen && (
                <>
                  <Box sx={{ mt: 2, color: "#242424" }}>
                    <Text variant="subtitle2">Add New Document</Text>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <DocumentNameText>Document name</DocumentNameText>
                      <TextField
                        onChange={(e) =>
                          handleNewFormDataChange({
                            value: e.target.value,
                            // value:{item.fileName},
                            name: "display_name",
                          })
                        }
                        value={newDocForm.display_name}
                        sx={{ marginTop: "6px" }}
                      />
                      {newDocError.display_name && (
                        <Text variant="small" sx={{ my: 1 }} color="red">
                          {newDocError.display_name}
                        </Text>
                      )}
                    </Grid>
                    <Grid item xs={5}>
                      <UploadDocText>Upload Document</UploadDocText>
                      <FileUpload
                        handleChange={({ name, value }) => {
                          handleNewFormDataChange({
                            name: "file",
                            value: value[0],
                          });
                        }}
                        acceptedFileTypes={[
                          "application/pdf",
                          "image/jpeg",
                          "image/png",
                        ]}
                      ></FileUpload>
                      {newDocError.file && (
                        <Text variant="small" sx={{ my: 1 }} color="red">
                          {newDocError.file}
                        </Text>
                      )}
                    </Grid>
                    <Grid xs={2}>
                      <Stack
                        sx={{ width: "100%", height: "100%" }}
                        direction="row"
                        alignItems="flex-end"
                      >
                        <Stack
                          direction="row"
                          ml={1}
                          mb={
                            newDocError?.file && newDocError?.display_name
                              ? 5.2
                              : 0.8
                          }
                          spacing={1}
                        >
                          <Button
                            size="large"
                            onClick={() => {
                              handelNewFormSubmit();
                            }}
                          >
                            Save
                          </Button>
                          <Button
                            size="large"
                            onClick={() => {
                              setIsDocFormOpen(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
          {!isDocFormOpen && (
            <AddDocumentButton
              onClick={() => {
                setIsDocFormOpen(true);
              }}
              variant="outlined"
            >
              + Add another document
            </AddDocumentButton>
          )}
        </Card>
      </Box>
    </>
  );
};

export default ProfileCard;
