import React, { useEffect, useState } from "react";
import { makeStyles, createStyles } from "@mui/styles";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Stack from "components/common/Stack";
import AppBar from "components/common/AppBar";
import ToolBar from "components/common/ToolBar";
import {
  color,
  display,
  fontSize,
  fontWeight,
  height,
  styled,
  textAlign,
} from "@mui/system";
import Button from "components/common/Button";
import Avatar from "components/common/Avatar";
import Box from "components/common/Box";
import Text from "components/common/Text";
import { useNavigate } from "react-router-dom";
import { localStore } from "stores/localStore";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "components/common/IconButton";
import { appConstants } from "helpers/constants/appConstants";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { CustomerDashboardApiAction } from "stores/redux/customerDashBoard/customerDashBoardApiSlice";
import Divider from "components/common/Divider";
import Badge from "components/common/Badge";

// User
const userRole = appConstants.userRoles;

const PROFILE_ROUTES = {
  [userRole.ADMIN.code]: "/admin/profile",
  [userRole.RIA.code]: "/profile",
  [userRole.CUSTOMER.code]: "/customer/profile",
  [userRole.SUBBROKER.code]: "/profile",
};

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: "40px",
  height: "40px",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    width: "40px",
    height: "40px",
  },
}));

const Header = (props) => {
  const {
    onLogoutClick,
    isDrawerExpanded,
    onDrawerToggle,
    selectedRoute,
    userRoleCode,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [buttonDisable, setButtonDisable] = useState(false)
  const [disabledButtons, setDisabledButtons] = useState({});


  const dispatch = useDispatch();

  const dashboardData = useSelector(
    (state) => state.commonReducer.dashboardData
  );

  // const [fetchNotifications, { data, isLoading, error }] =
  //   CustomerDashboardApiAction.getNotifications();


  const { data, refetch } = CustomerDashboardApiAction.getNotifications()

  const [showAllNotifications, setShowAllNotifications] = useState(false);

  const notificationCount = data?.notificationCount || 0;
  const [markAsSeen] = CustomerDashboardApiAction.markNotificationsAsSeen();

  const notifications = data?.notifications || [];

  useEffect(() => {
    refetch()
  }, []);

  const classes = useStyles();
  const navigate = useNavigate();

  const formatExpiryDate = (dateString) => {
    const parts = dateString.split("-");
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationIconClick = (event) => {
    setAnchorEl(event.currentTarget);
    setShowAllNotifications(true);
  };

  const handleMenuItemClick = async (notificationId) => {
    // Check if the button is already disabled
    if (disabledButtons[notificationId]) {
      return; // Do not proceed if already clicked
    }

    try {
      // Mark the button as disabled immediately to avoid multiple clicks
      setDisabledButtons((prev) => ({ ...prev, [notificationId]: true }));

      let res = await markAsSeen([notificationId]);

      if (res.data.status === 1) {
        refetch();
      } else {
        // If API call fails, re-enable the button
        setDisabledButtons((prev) => ({ ...prev, [notificationId]: false }));
      }

      console.log({ res });
    } catch (error) {
      // Re-enable the button in case of an error
      setDisabledButtons((prev) => ({ ...prev, [notificationId]: false }));
      console.log(error);
    }
  };


  const profilePath = PROFILE_ROUTES[userRoleCode];
  return (
    <>
      <AppBar className={classes.appBarRoot} position="sticky" elevation={0}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box ml={2}>
            <Text color="#242424" variant="body1" sx={{ fontWeight: 500 }}>
              {selectedRoute}
            </Text>
          </Box>

          {selectedRoute === "Dashboard" &&
            userRoleCode == appConstants.userRoles.RIA.code &&
            dashboardData && (
              <Box
                display="flex"
                flexDirection="row"
                px={2}
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: "auto",
                  backgroundColor: "#E5EEFF",
                  height: "36px",
                  borderRadius: "8px",
                }}
              >
                <Box display="flex" flexDirection="row" justifyContent="center">
                  <Text
                    variant="small"
                    color="#667085"
                    sx={{ marginRight: "6px" }}
                  >
                    SEBI registration no. :
                  </Text>
                  <Text variant="small" color="#101828">
                    {dashboardData?.sebiregno}
                  </Text>
                </Box>
                <Box
                  ml={4}
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  sx={{ width: "auto" }}
                >
                  <Text
                    variant="small"
                    color="#667085"
                    sx={{ marginRight: "6px" }}
                  >
                    BASL no. :{" "}
                  </Text>
                  <Text variant="small" color="#101828">
                    {dashboardData?.baslNo}
                  </Text>
                </Box>
                <Box
                  ml={4}
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  sx={{ width: "auto" }}
                >
                  <Text
                    variant="small"
                    color="#667085"
                    sx={{ marginRight: "6px" }}
                  >
                    Expiry date :{" "}
                  </Text>
                  <Text variant="small" color="#101828">
                    {formatExpiryDate(dashboardData?.expirydate)}
                  </Text>
                </Box>
              </Box>
            )}

          <ToolBar className={classes.toolBarRoot}>
            <Box direction="row" justifyContent="flex-end" alignItems="center">
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={1.5}
                sx={{ width: "100%" }}
                alignItems="center"
              >
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1.5}
                >
                  <IconButton
                    onClick={(event) => handleNotificationIconClick(event)}
                  >
                    <Badge
                      badgeContent={notificationCount}
                      color="error"
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      max={999}
                    >
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={isMenuOpen}
                    onClose={handleMenuClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    sx={{
                      maxHeight: "400px",
                      width: "500px",
                    }}
                  >
                    {notificationCount === 0 ? (
                      <Box>
                        <Text
                          sx={{
                            width: "300px",
                            height: "30px",
                            textAlign: "center",
                          }}
                        >
                          No Notification{" "}
                        </Text>
                      </Box>
                    ) : (
                      <Stack spacing={0}>
                        {notifications.map((notification) => (
                          <React.Fragment key={notification.notification_id}>
                            <MenuItem
                              sx={{
                                fontSize: "12px",
                                fontWeight: "500",
                                lineHeight: "1.5",
                                cursor:
                                  notification.is_read === 1
                                    ? "default"
                                    : "pointer",
                                color:
                                  notification.is_read === 1
                                    ? "gray"
                                    : "inherit",
                              }}
                            >
                              {notification.is_read === 1 ? (
                                <Text
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    wordWrap: "break-word",
                                    overflow: "visible",
                                    lineHeight: 1.5,
                                    whiteSpace: "normal",
                                    width: "500px",
                                  }}
                                >
                                  {notification.message}
                                </Text>
                              ) : (
                                <>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      mb: 0.5,
                                    }}
                                  >
                                    <Text sx={{ fontSize: "14px" }}>
                                      {notification.message.substring(0, 30)}...
                                    </Text>

                                    <Text
                                      sx={{
                                        textTransform: "none",
                                        fontSize: "11px",
                                        color: "blue",
                                        cursor: "pointer",
                                      }}
                                      disabled={disabledButtons[notification.notification_id]}
                                      onClick={() =>
                                        notification.is_read === 1
                                          ? null
                                          : handleMenuItemClick(
                                            notification.notification_id
                                          )
                                      }
                                    >
                                      {disabledButtons[notification.notification_id]
                                        ? "Loading..."
                                        : "Read More"}
                                    </Text>
                                  </Box>
                                </>
                              )}
                            </MenuItem>
                            {notifications.length > 1 && <Divider />}
                          </React.Fragment>
                        ))}
                      </Stack>
                    )}
                  </Menu>

                  <StyledAvatar
                    variant="h3"
                    src="./images/avatar.jpg"
                    title={"Faisal"}
                    onClick={() => {
                      navigate(profilePath);
                    }}
                  />
                </Stack>
              </Stack>
            </Box>
          </ToolBar>
        </Box>
      </AppBar>
    </>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    appBarRoot: {
      backgroundColor: `${theme.palette.background.paper} !important`,
      color: "#1e1e1e !important",
      display: "flex",
      flexDirection: "row",
      boxShadow: "0px 0px 10px #ebebeb",
    },

    toolBarRoot: {
      justifyContent: "flex-end",
    },

    expandedNotificationText: {
      fontSize: "8px",
      display: "flex",
      flexWrap: "wrap",
      wordWrap: "break-word",
      overflow: "visible",
      lineHeight: 1.5,
      whiteSpace: "normal",
      width: "500px",
    },
  })
);

export default React.memo(Header);
