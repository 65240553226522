import React, { useState } from "react";
import Box from "components/common/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Text from "components/common/Text";
import CustomerSummary from "./CustomerSummary";
import CustomerDetail from "./CustomerDetail";
import CustomerAllocation from "./CustomerAllocation";
import CustomerGainLossTable from "./CustomerGainLossTable";
import CustomerHoldingTable from "./CustomerHoldingTable";
import CustomerHistoryTable from "./CustomerHistoryTable";

const CustomerTableTab = ({
  customerId,
  productId,
  showTableTab,
  familyList,
  memberID,
}) => {
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            backgroundColor: "#FFFFFF",
          }}
        >
          <TabList onChange={handleChange} aria-label="lab API tabs example"
          sx={{
            "& .MuiTabs-indicator":{
              position :"fixed"
            }
          }}>
            <Tab
              label={
                <Text
                  variant="medium"
                  component="h5"
                  fontWeight="600"
                  style={{ marginLeft: "10px" }}
                >
                  Summary
                </Text>
              }
              value="1"
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                borderBottom: value === "1" ? "2px solid black" : "none",
              }}
            />

            <Tab
              label={
                <Text
                  variant="small"
                  component="h5"
                  fontWeight="600"
                  style={{ marginLeft: "10px" }}
                >
                  Details
                </Text>
              }
              value="2"
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                borderBottom: value === "2" ? "2px solid black" : "none", 
              }}
            
            />

            {productId === 2 ? (
              <Tab
                label={
                  <Text
                    variant="small"
                    component="h5"
                    fontWeight="600"
                    style={{ marginLeft: "10px" }}
                  >
                    Allocation
                  </Text>
                }
                value="3" 
                sx={{
                  textTransform: "none",
                  fontWeight: "bold",
                  borderBottom: value === "3" ? "2px solid black" : "none", 
                }}
              />
            ) : (
              ""
            )}

            <Tab
              label={
                <Text
                  variant="small"
                  component="h5"
                  fontWeight="600"
                  style={{ marginLeft: "10px" }}
                >
                  Gain/Loss
                </Text>
              }
              value="4"
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                borderBottom: value === "4" ? "2px solid black" : "none", 
              }}
            />

            <Tab
              label={
                <Text
                  variant="small"
                  component="h5"
                  fontWeight="600"
                  style={{ marginLeft: "10px" }}
                >
                 Holdings
                </Text>
              }
              value="5"
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                borderBottom: value === "5" ? "2px solid black" : "none", 
              }}
            />
             <Tab
              label={
                <Text
                  variant="small"
                  component="h5"
                  fontWeight="600"
                  style={{ marginLeft: "10px" }}
                >
                 History
                </Text>
              }
              value="6"
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                borderBottom: value === "6" ? "2px solid black" : "none", 
              }}
            />
          </TabList>
        </Box>
        <TabPanel value="1" style={{ padding: "0px", marginTop: "25px" }}>
          <CustomerSummary
            customerId={customerId}
            productId={productId}
            familyList={familyList}
            showTableTab={showTableTab}
            memberID={memberID}
          />
        </TabPanel>
        <TabPanel value="2" style={{ padding: "0px", marginTop: "25px" }}>
          <CustomerDetail
            customerId={customerId}
            productId={productId}
            memberID={memberID}
            //  blockID={blockID}
            familyList={familyList}
          />
        </TabPanel>
        <TabPanel value="3" style={{ padding: "0px", marginTop: "25px" }}>
          <CustomerAllocation
            customerId={customerId}
            productId={productId}
            memberID={memberID}
            // blockID={blockID}
            familyList={familyList}
          />
        </TabPanel>
        <TabPanel value="4" style={{ padding: "0px", marginTop: "25px" }}>
          <CustomerGainLossTable
            customerId={customerId}
            productId={productId}
            memberID={memberID}
            //  blockID={blockID}
            familyList={familyList}
          />
        </TabPanel>

        <TabPanel value="5" style={{ padding: "0px", marginTop: "25px" }}>
          <CustomerHoldingTable
            customerId={customerId}
            productId={productId}
            memberID={memberID}
            //  blockID={blockID}
            familyList={familyList}
          />
        </TabPanel>
        <TabPanel value="6" style={{ padding: "0px", marginTop: "25px" }}>
          <CustomerHistoryTable
            customerId={customerId}
            productId={productId}
            memberID={memberID}
            //  blockID={blockID}
            familyList={familyList}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default CustomerTableTab;
