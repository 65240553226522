import { createApi } from "@reduxjs/toolkit/query/react";
import { apiSliceInterceptor } from "stores/redux/apiSliceInterceptor";

const adminDashBoard = createApi({
  reducerPath: "adminProfile",
  baseQuery: apiSliceInterceptor.baseQueryWithInterceptor,
  tagTypes: ["GETDASHBOARD", "ADDRIAUSER", "GETRIATABLE", "GETSINGLERIA"],

  endpoints: (qb) => ({
    getProfileApi: qb.query({
      query: () => {
        return `api/admin/get-admin-profile`;
      },
      transformResponse: (response) => response.result,
      providesTags: ["GETPROFILE"],
    }),

    getRiaTable: qb.query({
      query: ({ status, searchQueryList, page, take }) => {
        return `api/admin/ria/get-ria?status=${status}&search=${searchQueryList}&page=${page}&take=${take}`;
      },
      transformResponse: (response) => response.message,
      providesTags: (result, error, { status, search, page, take }) => {
        if (error) {
          return ["GETRIATABLE"];
        }
        return [{ type: "GETRIATABLE", status, search, page, take }];
      },
    }),

    getSingleRia: qb.query({
      query: (userID) => {
        return `api/admin/ria/get-single-ria/${userID}`;
      },
      // transformResponse: (response) => response,
      providesTags: ["GETSINGLERIA"],
    }),

    selectDateRange: qb.mutation({
      query: ({ startDate, endDate }) => {
        return {
          url: `api/admin/ria/get-ria-status`,
          method: "POST",
          body: { startDate, endDate },
        };
      },
    }),

    riaUpdateStatus: qb.mutation({
      query: ({ status, userId }) => {
        return {
          url: `api/admin/ria/ria-update-status/${userId}`, // Include userId in the URL
          method: "PUT",
          body: { status },
        };
      },
    }),

    // add RIA api
    addRiaUserApi: qb.mutation({
      query: ({
        name,
        email,
        mobile,
        sebi_reg_no,
        basl_no,
        basl_expiry,
        files,
        user_type,
        portal_expiry_date,
        sebi_expiry,
      }) => ({
        url: `api/admin/ria/add-ria`,
        method: "POST",
        body: {
          name,
          email,
          mobile,
          sebi_reg_no,
          basl_no,
          basl_expiry,
          files,
          user_type,
          sebi_expiry,
          portal_expiry_date
        },
        invalidatesTags: ["ADDRIAUSER"],
      }),
    }),

    //add Sub-Broker api
    addSubbrokerUserApi: qb.mutation({
      query: ({
        user_type,
        sub_broker_data
      }) => ({
        url: `api/admin/ria/add-sub-broker`,
        method: "POST",
        body: {
          user_type,
          sub_broker_data
        },
        invalidatesTags: ["ADDSUBBROKERUSER"],
      }),
    }),

    getProfileDocApi: qb.query({
      query: (downloadFileId) => {
        return `api/admin/ria-doc/download?user_document_id=${downloadFileId}`;
      },
      transformResponse: (response) => response.result,
      providesTags: ["GETDOCUMENT"],
    }),
  }),
});

export const adminDashBoardApiReducer = adminDashBoard.reducer;

export const adminDashBoardApiAction = {
  middleware: adminDashBoard.middleware,
  reducerPath: adminDashBoard.reducerPath,
  getProfileApi: adminDashBoard.useGetProfileApiQuery, // table get api
  getRiaTable: adminDashBoard.useGetRiaTableQuery, // table get api
  getSingleRia: adminDashBoard.useLazyGetSingleRiaQuery,
  selectDateRange: adminDashBoard.useSelectDateRangeMutation, // date post api
  addRiaUserApi: adminDashBoard.useAddRiaUserApiMutation, // Add RIA user to post
  addSubbrokerUserApi: adminDashBoard.useAddSubbrokerUserApiMutation, //Add Subbroker to post
  getProfileDocApi: adminDashBoard.useGetProfileDocApiQuery, // for Download the doc
  getLazyProfileDocApi: adminDashBoard.useLazyGetProfileDocApiQuery, // for Download the doc
  riaUpdateStatus: adminDashBoard.useRiaUpdateStatusMutation,
};
