import AddIcon from "@mui/icons-material/Add";
import Chip from "@mui/material/Chip";

// Common Components.
import Box from "components/common/Box";
import Text from "components/common/Text";
import Stack from "components/common/Stack";
import Button from "components/common/Button";
import TextField from "components/common/TextField";

import CloudDownload from "asset/icons/CloudDownload";
import ImportIcon from "asset/ImportIcon";
import FilterIcon from "asset/icons/FilterIcon";

const tadeType = ["", "Custom", "Model Portfolio", "Algo", "positions"];

const TradeHeader = ({
  handleCustomFilterDrawer,
  handleTradeDrawerOpen,
  handleSearch,
  initateImportTrade,
  filterData,
  totalRecord = 0,
  PRODUCT_ID,
  onDownloadExcel,
  tradesDetailsList,
  handleSquareOff ,
  selectedRows,
  sqareOffApiLoading,
  handleImportButtonClick,
  openImportFileModal,
  handleDirectUpload,
  positionList
}) => {
 const totalPosition = positionList?.data?.positionsCount
 const totalTrades =
 tadeType[PRODUCT_ID] === "positions"
   ? `${totalPosition} Trades`
   : `${totalRecord} Trades`;


  return (
    <>
      <Box sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="space-between" sx>
          <Box>
            <Text variant="body1" sx={{ fontWeight: 500, color: "#101828" }}>
              <Box component="span" mr="8px">
                {tadeType[PRODUCT_ID]}
              </Box>
              <Chip label={totalTrades}></Chip>
            </Text>
            <Text variant="small" color="#667085" sx={{ mt: 0.5 }}>
              Here you can view and add customers manually or by importing.
            </Text>
          </Box>

          {/* Import & Add Trade */}
          {PRODUCT_ID === 2 || PRODUCT_ID === 4 ? null : (
            <Stack direction="row" alignItems="center" spacing={2}>
                <Button
                sx={{
                  fontWeight: 500,
                  borderRadius: "8px",
                  borderColor: "#D0D5DD",
                  p: 1,
                }}
                variant="outlined"
                startIcon={<ImportIcon />}
                onClick={handleDirectUpload}
              >
                Direct Import 
              </Button>
              <Button
                sx={{
                  fontWeight: 500,
                  borderRadius: "8px",
                  borderColor: "#D0D5DD",
                  p: 1,
                }}
                variant="outlined"
                startIcon={<ImportIcon />}
                onClick={handleImportButtonClick}
              >
                Import
              </Button>
              <Button
                sx={{ fontWeight: 500, borderRadius: "8px", p: 1 }}
                startIcon={<AddIcon />}
                onClick={handleTradeDrawerOpen}
              >
                Add Trade
              </Button>
            </Stack>
          )}
        </Stack>
        {PRODUCT_ID === 4 ? null : (
        <Stack direction="row" justifyContent="space-between" mt={4}>
          <TextField
            sx={{ maxWidth: "450px" }}
            placeholder="Search"
            value={filterData.search}
            onChange={handleSearch}
          />
          <Stack direction="row" alignItems="center" spacing={2}>
            {filterData.isSquareOff == "0" &&
            selectedRows &&
            selectedRows.length > 1 ? (
              <Button
                sx={{
                  fontWeight: 500,
                  borderRadius: "8px",
                  borderColor: "#D0D5DD",
                  p: 1,
                }}
                variant="outlined"
                onClick={handleSquareOff}
                disabled={sqareOffApiLoading}
              >
                Square Off Selected Trades
              </Button>
            ) : null}

           
              <>
                <Button
                  sx={{
                    fontWeight: 500,
                    borderRadius: "8px",
                    borderColor: "#D0D5DD",
                    p: 1,
                  }}
                  variant="outlined"
                  startIcon={<CloudDownload />}
                  onClick={() => onDownloadExcel()}
                  disabled={!tradesDetailsList.length}
                >
                  Download
                </Button>
                <Button
                  sx={{
                    fontWeight: 500,
                    borderRadius: "8px",
                    borderColor: "#D0D5DD",
                    p: 1,
                  }}
                  startIcon={<FilterIcon />}
                  variant="outlined"
                  onClick={handleCustomFilterDrawer}
                >
                  Add Filters
                </Button>
              </>
          </Stack>
        </Stack>
            )}
      </Box>
    </>
  );
};

export default TradeHeader;
