import _get from "lodash/get";
import { uuid } from "helpers/utility";
import { result } from "lodash";


const tradeParser = {};

tradeParser.tradesListing = (data = []) => {
  let parsedData = data?.map((item) => {
    return {
      id: uuid(),
      customerName: _get(item, "customer_name", ""),
      stockName: _get(item, "stock_name", ""),
      quantity: _get(item, "quantity", ""),
      buyDate: _get(item, "buy_date", ""),
      sellDate: _get(item, "sell_date", ""),
      amountToInvest: _get(item, "amount_invested", ""),
      totalGains: _get(item, "total_gains", ""),
      amountAfterGains: _get(item, "amount_after_gains", ""),
      todaysChange: _get(item, "todays_change", ""),
      taxPl: _get(item, "tax_pl", ""),
      riskProfile: _get(item, "risk_profile", ""),
      customerId: _get(item, "customer_id", ""),
      tradeId: _get(item, "trade_id", ""),
      transactionId: _get(item, "transaction_id", ""),
      sqaureOffStatus: _get(item, "sqaure_off_status", ""),
      is_square_off: _get(item, "is_square_off",),
      order_type: _get(item, "order_type", ""),
      product_type: _get(item, "product_type", ""),
      stop_loss: _get(item, "stop_loss", ""),
      price: _get(item, "price", "")
    };
  });
  return parsedData;
}

tradeParser.importTradeFile = (data = []) => {
  let parsedData = [];
  if (!data || !data.length) {
    return parsedData;
  }
  parsedData = [...data];
  parsedData = parsedData.map((item) => ({
    product_id: _get(item, "product_id", ""),
    product_type: _get(item, "product_type", ""),
    customer_id: _get(item, "customer_id", ""),
    customer_name: _get(item, "customer_name", ""),
    broker_name: _get(item, "broker_name", ""),
    login_id: _get(item, "login_id", ""),
    risk_profile_id: _get(item, "risk_profile_id", ""),
    risk_name: _get(item, "risk_name", ""),
    action: _get(item, "action", ""),
    order_type: _get(item, "order_type", ""),
    symbol: _get(item, "symbol", ""),
    exchange: _get(item, "exchange", ""),
    expiry_date: _get(item, "expiry_date", ""),
    expiry_time: _get(item, "expiry_time", ""),
    is_active: _get(item, "is_active", ""),
    is_percent: _get(item, "is_percent", ""),
    percent: _get(item, "percent", ""),
    is_stop_loss: _get(item, "is_stop_loss", ""),
    stop_loss: _get(item, "stop_loss", ""),
    message: _get(item, "message", ""),
    price: _get(item, "price", ""),
    is_target: _get(item, "is_target", ""),
    target: _get(item, "target", ""),
    quantity: _get(item, "quantity", ""),
    lot: _get(item, "lot", ""),
    lot_size: _get(item, "lot_size", ""),
    status: _get(item, "status", 0),
    product_type: _get(item, "product_type", 0),
    trade_id: _get(item, "trade_id", ""),
    amount_invested: _get(item, "amount_invested", ""),
    ucc: _get(item, "ucc", ""),
  }))

  // Sort error data to top
  parsedData.sort((a, b) => a.status > b.status ? 1 : -1);
  return parsedData;
}

tradeParser.proceedImportTrade = (data = []) => {
  let parsedData = [];
  if (!data || !data.length) {
    return parsedData;
  }
  parsedData = [...data];
  parsedData = parsedData.map((item) => ({
    product_id: _get(item, "product_id", ""),
    product_type: _get(item, "product_type", ""),
    customer_id: _get(item, "customer_id", ""),
    customer_name: _get(item, "customer_name", ""),
    broker_name: _get(item, "broker_name", ""),
    login_id: _get(item, "login_id", ""),
    risk_profile_id: _get(item, "risk_profile_id", ""),
    risk_name: _get(item, "risk_name", ""),
    action: _get(item, "action", ""),
    order_type: _get(item, "order_type", ""),
    symbol: _get(item, "symbol", ""),
    exchange: _get(item, "exchange", ""),
    expiry_date: _get(item, "expiry_date", ""),
    expiry_time: _get(item, "expiry_time", ""),
    is_active: _get(item, "is_active", ""),
    is_percent: _get(item, "is_percent", ""),
    percent: _get(item, "percent", ""),
    is_stop_loss: _get(item, "is_stop_loss", ""),
    stop_loss: _get(item, "stop_loss", ""),
    message: _get(item, "message", ""),
    price: _get(item, "price", ""),
    is_target: _get(item, "is_target", ""),
    target: _get(item, "target", ""),
    quantity: _get(item, "quantity", ""),
    lot_size: _get(item, "lot_size", ""),
    status: _get(item, "status", 0),
    tradeStatus: _get(item, "tradeStatus", ""),
    amount_invested: _get(item, "amount_invested", 0),
    login_id: _get(item, "login_id", ""),
    remaining_quantity: _get(item, "remaining_quantity", 0),
    sold_quantity: _get(item, "sold_quantity", 0),
    risk_profile_name: _get(item, "risk_profile_name", 0),
    product_type: _get(item, "product_type", 0),
    trade_id: _get(item, "trade_id", ""),
  }))

  // Sort error data to top
  parsedData.sort((a, b) => a.status > b.status ? 1 : -1);
  return parsedData;
}

tradeParser.ProceedToImportTradeRes = (data = []) => {
  let parsedData = [];
  if (!data || !data.length) {
    return parsedData;
  }
  parsedData = data.map((item) => ({
    action: _get(item, "action", ""),
    company_id: _get(item, "company_id", ""),
    created_by: _get(item, "created_by", ""),
    created_date: _get(item, "created_date", ""),
    customer_id: _get(item, "customer_id", ""),
    customer_name: _get(item, "customer_name", ""),
    exchange: _get(item, "exchange", ""),
    expiry_date: _get(item, "expiry_date", ""),
    expiry_time: _get(item, "expiry_time", ""),
    amountToInvest: _get(item, "investment_amount", ""),
    is_active: _get(item, "is_active", ""),
    is_percent: _get(item, "is_percent", ""),
    is_stop_loss: _get(item, "is_stop_loss", ""),
    lot_size: _get(item, "lot_size", ""),
    order_type: _get(item, "order_type", ""),
    percent: _get(item, "percent", ""),
    price: _get(item, "price", ""),
    product_id: _get(item, "product_id", ""),
    product_type: _get(item, "product_type", ""),
    quantity: _get(item, "quantity", ""),
    risk_profile_name: _get(item, "risk_name", ""),
    risk_profile_id: _get(item, "risk_profile_id", ""),
    stop_loss: _get(item, "stop_loss", ""),
    symbol: _get(item, "symbol", ""),
    target: _get(item, "target", ""),
    trade_id: _get(item, "trade_id", ""),
    updated_by: _get(item, "updated_by", ""),
    updated_date: _get(item, "updated_date", ""),
    user_id: _get(item, "user_id", ""),
  }))
  return parsedData;
}
tradeParser.listPosition = (data = [], positionsount) => {
  let positionsCount = data?.total_record
  let parsedData = data?.result?.map((item) => {
    return {
      id: _get(item, "id", ""),
      ucc: _get(item, "ucc", ""),
      customer_name: _get(item, "customer_name", ""),
      symbol: _get(item, "symbol", ""),
      exchange: _get(item, "exchange", ""),
      buy_quantity: _get(item, "buy_quantity", ""),
      buy_avg: _get(item, "buy_avg", ""),
      sell_quantity: _get(item, "sell_quantity", ""),
      sell_avg: _get(item, "sell_avg", ""),
      net_quantity: _get(item, "net_quantity", ""),
      net_avg: _get(item, "net_avg", ""),
      net_Lot: _get(item, "net_Lot", ""),
    }

  }

  )

  return { parsedData, positionsCount };


}


export { tradeParser };