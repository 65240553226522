import React, { useEffect, useState, useRef } from "react";
import Box from "components/common/Box";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import MyDatePicker from "components/common/MyDatePicker";
import DashboardMultipleSelect from "./DashboardMultipleSelect";
import { CustomerDashboardApiAction } from "stores/redux/customerDashBoard/customerDashBoardApiSlice";
import dayjs from "dayjs";
import useDebounce from "hooks/Debounce";

const CustomerDashboardHeader = ({
  userName,
  apiresponse,
  cbWithFilteredData,
  refetchStacksDataCounter,
  setStacksLoading,
  pendingCustomerApprv,
  setGraphCustomData, // Setter for custom graph data
  setGraphAlgoData,
}) => {
  const { data: investmentTypeData = {}, refetch: refetchInvestmentTypeData } =
    CustomerDashboardApiAction.getInvesmentTypeApi();

  const [dropDownData, setDropDownData] = useState({
    investmentType: [],
    familyMember: [],
  });
  const [formattedStartDate, setFormattedStartDate] = useState(null);
  const [formattedEndDate, setFormattedEndDate] = useState(null);

  const intervalRef = useRef(null);
  const dropDownDataRef = useRef(dropDownData); // Ref for keeping track of dropDownData state

  const payload = {
    user_id: userName.user_id, // this username is coming from redux
  };

  const [refetchFamilyMemberData, { data: familyMemberData = {} }] =
    CustomerDashboardApiAction.getFamilyMemberApi(payload);

  const [handleDatePicker] =
    CustomerDashboardApiAction.addDashBoardDatepickerApi();

  useEffect(() => {
    // Update the ref whenever dropDownData changes
    dropDownDataRef.current = dropDownData;
  }, [dropDownData]);

  useEffect(() => {
    fetchFilteredData();
    intervalRef.current = setInterval(fetchFilteredData, 5000); // 5000ms = 5 seconds

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []); // Empty dependency array ensures this runs once on mount

  useEffect(() => {
    if (pendingCustomerApprv) {
      return;
    }

    refetchInvestmentTypeData();

    if (payload && payload.user_id) {
      if (userName.is_admin === 1) {
        refetchFamilyMemberData(payload);
      }
    }
  }, [pendingCustomerApprv]);

  const debouncedValue = useDebounce(dropDownData, 1000);

  useEffect(() => {
    if (pendingCustomerApprv) {
      return;
    }

    fetchFilteredData();
    const { investmentType, familyMember } = debouncedValue;
    cbWithFilteredData({
      investmentType,
      familyMember,
      formattedStartDate,
      formattedEndDate,
    });
  }, [debouncedValue, formattedEndDate, refetchStacksDataCounter]);

  // Fetch Data with Filter Params
  const fetchFilteredData = async () => {
    setStacksLoading(true);
    try {
      const { investmentType, familyMember } = dropDownDataRef.current; // Use the latest state value from the ref
      let payload = {
        product_id: investmentType?.length ? investmentType : [1, 2, 3],
        member_ids: familyMember?.length ? familyMember : [userName.user_id],
        date_range_from: formattedStartDate,
        date_range_to: formattedEndDate,
      };

      const res = await handleDatePicker(payload);
      setStacksLoading(false);
      apiresponse(res.data);
    } catch (e) {
      setStacksLoading(false);
    } finally {
      setStacksLoading(false);
    }
  };

  const fetchGraphData = async (productId, setter) => {
    setStacksLoading(true);
    try {
      const { familyMember } = dropDownDataRef.current;

      let payload = {
        product_id: productId,
        member_ids: familyMember?.length ? familyMember : [userName.user_id],
        date_range_from: formattedStartDate,
        date_range_to: formattedEndDate,
      };

      const res = await handleDatePicker(payload);
      setStacksLoading(false);
      setter(res?.data?.card_data); // Use setter to update container state
    } catch (e) {
      setStacksLoading(false);
    }
  };

  useEffect(() => {
    fetchGraphData([1], setGraphCustomData); // Fetch custom graph data and update container
    fetchGraphData([3], setGraphAlgoData); // Fetch algo graph data and update container
  }, []);

  const handleChange = async (name, event) => {
    const {
      target: { value },
    } = event;
    setDropDownData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateRangeChange = async (newDateRange) => {
    const startDateFormatted = newDateRange[0]
      ? dayjs(newDateRange[0]).format("YYYY-MM-DD")
      : dayjs().subtract(1, "month").format("YYYY-MM-DD");
    const endDateFormatted = newDateRange[1]
      ? dayjs(newDateRange[1]).format("YYYY-MM-DD")
      : dayjs().format("YYYY-MM-DD");
    setFormattedStartDate(startDateFormatted);
    setFormattedEndDate(endDateFormatted);
  };

  let memberNameList = [];
  let productList = [];

  if (familyMemberData && familyMemberData.length) {
    memberNameList = familyMemberData?.map((m) => ({
      
      id: m.user_id,
      displayName: m.name,
    }));
  }
  if (
    investmentTypeData &&
    investmentTypeData.products &&
    investmentTypeData.products.length
  ) {
    productList = investmentTypeData.products.map((p) => ({
      id: p.product_id,
      displayName: p.product_name,
    }));
  }

  return (
    <>
      <Stack
        display="flex"
        direction="row"
        justifyContent="space-between"
        sx={{ width: "100%", mb: "20px", mt: "24px" }}
      >
        <Stack sx={{ width: "70%" }}>
          <Text variant="title" sx={{ mb: "5px" }}>
            Welcome {userName.name} 👋
          </Text>
          <Text variant="body2" sx={{ color: "#676C76" }}>
            We are glad to see you here, thank you for choosing us.
          </Text>
        </Stack>

        <Box sx={{ display: "flex" }}>
          {investmentTypeData.products && (
            <DashboardMultipleSelect
              dropDownData={productList}
              dropDownLabel={"Investment type"}
              selectedValues={dropDownData.investmentType}
              handleChange={(e) => handleChange("investmentType", e)}
            />
          )}

          {userName.is_admin === 1 &&
            memberNameList &&
            memberNameList.length && (
              <DashboardMultipleSelect
                dropDownData={memberNameList}
                dropDownLabel={"Family members"}
                selectedValues={dropDownData.familyMember}
                handleChange={(e) => handleChange("familyMember", e)}
              />
            )}
        </Box>

        <Stack sx={{ width: "30%" }}>
          <MyDatePicker onDateRangeChange={handleDateRangeChange} />
        </Stack>
      </Stack>
    </>
  );
};

export default CustomerDashboardHeader;
