import React,{useState} from "react";
import Grid from "components/common/Grid";
import Text from "components/common/Text";
import Stack from "components/common/Stack";
import Box from "components/common/Box";
import CustomerDetails from "components/addCustomer/CustomerDetails";
import CustomerAnswers from "components/Customer/CustomerAnswers";
import CustomerQuestions from "components/Customer/CustomerQuestions";

function CustomerRiskAssesmentContainer({ handleStepChange}) {


	const [isBasicDetailsCompleted, setIsBasicDetailsCompleted] =
		useState(true);
	const [answer, setAnswer] = useState("");

	const handleOtpSubmit = () => {
		setIsBasicDetailsCompleted(true);
	};

	const handleAnswers = (e) => {
		const { name, value } = e.target;
		setAnswer(value);
	};

	const handleSubmit = (e) => {
		handleStepChange(2);
		return null;
	};

  return (
    <>
      {isBasicDetailsCompleted ? (
        <Box sx={{backgroundColor: "#F7F8FF"}}>
          <Grid container>
            <Grid item xs={12}sx={{ml:6,mt:2}}>
            <Text sx={{color:"#242424",fontsize:"24px",fontweight:500}}>Risk assessment</Text>
            <Text sx={{color:"#676C76",fontsize:"16px",fontweight:400}}>Answer the following questions to know whether you are aggressive, modrate or conservative risk taker.</Text>
    
            </Grid>

            <Grid item xs={6}>
              <Stack justifyContent="center" alignItems="center" height="100%" width="100%">
                <CustomerQuestions currentQtsId={0} />
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <Stack justifyContent="center" alignItems="center" height="100%">
                <CustomerAnswers 
                  onchange={handleAnswers} 
                  onClick={handleSubmit} 
                  />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <CustomerDetails handleOtpSubmit={handleOtpSubmit} />
      )}
    </>
  );
}

export default CustomerRiskAssesmentContainer;
