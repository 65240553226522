import { createApi } from "@reduxjs/toolkit/query/react";
import { apiSliceInterceptor } from "stores/redux/apiSliceInterceptor";

const CustomerPortfolio = createApi({
  reducerPath: "CustomerPortfolio",
  baseQuery: apiSliceInterceptor.baseQueryWithInterceptor,
  tagTypes: [
    "GETFAMILYMEMBERAPI",
    "GETSUMMARYDETAILSAPI",
    "GETDETAILSAPI",
    "GETALLOCATIONAPI",
  ],
  endpoints: (qb) => ({
    getFamilyMemberApi: qb.query({
      query: (payload, userId, productId) => {
        return `api/customer/get-family-members-by-product/${payload.userId}/${payload.productId}`; // Adjust the endpoint as needed
      },
      transformResponse: (response) => response.result,
      providesTags: (result, error, userId, productId) => [
        { type: "GETFAMILYMEMBERAPI", userId, productId },
      ],
    }),

    getSummaryDetailAPI: qb.query({
      query: ({ product_id, user_id, page, pageSize }) => {
        return `api/customer/get-portfolio-summary?product_id=${product_id}&user_id=${user_id}&page=${page}&take=${pageSize}`;
      },
      transformResponse: (response) => ({
        data: response.result,
        totalRecords: response.total_record,
      }),
      providesTags: ["GETPERFORMANCEDETAIL"],
    }),

    getHistoryDetailAPI: qb.query({
      query: ({ product_id, user_id, page, pageSize }) => {
        return `api/customer/get-portfolio-summary?product_id=${product_id}&user_id=${user_id}&page=${page}&take=${pageSize}&trade_history=true`;
      },
      transformResponse: (response) => ({
        data: response.result,
        totalRecords: response.total_record,
      }),
      providesTags: ["GETHISTORYDETAIL"],
    }),

    getDetailAPI: qb.query({
      query: ({ user_id, product_id, page, pageSize }) => {
        return `api/customer/portal/details-api?product_id=${product_id}&user_id=${user_id}&page=${page}&take=${pageSize}`;
      },
      transformResponse: (response) => ({
        data: response.result,
        totalRecords: response.total_record,
      }),
      providesTags: [`GET-Detail-Table`],
    }),

    getGainLossAPI: qb.query({
      query: ({ user_id, product_id, page, pageSize }) => {
        return `api/customer/gain-loss?product_id=${product_id}&user_id=${user_id}&page=${page}&take=${pageSize}`;
      },
      transformResponse: (response) => ({
        data: response.result,
        totalRecords: response.total_record,
      }),
      providesTags: [`GET-GainLoss-Table`],
    }),

    getHoldingAPI: qb.query({
      query: ({ user_id, product_id, page, pageSize }) => {
        return `api/customer/portal/holdings?product_id=${product_id}&user_id=${user_id}&page=${page}&take=${pageSize}`;
      },
      transformResponse: (response) => ({
        data: response.result,
        totalRecords: response.total_record,
      }),
      providesTags: [`GET-Holding-Table`],
    }),

    getAllocationAPI: qb.query({
      query: ({ user_id, product_id, member_id = null, page, pageSize }) => {
        return {
          url: `api/customer/model/customer-allocation-details/${user_id}/${product_id}`,
          params: {
            page: page,
            take: pageSize,
            member_id,
          },
        };
      },
      transformResponse: (response) => ({
        data: response.result,
        totalRecords: response.total_record,
      }),
      providesTags: [`GET-Allocation-Table`],
    }),
  }),
});

export const CustomerPortfolioApiReducer = CustomerPortfolio.reducer;

export const CustomerPortfolioApiAction = {
  middleware: CustomerPortfolio.middleware,
  reducerPath: CustomerPortfolio.reducerPath,
  getFamilyMemberApi: CustomerPortfolio.useGetFamilyMemberApiQuery,
  getSummaryDetailAPI: CustomerPortfolio.useLazyGetSummaryDetailAPIQuery,
  getHistoryDetailAPI: CustomerPortfolio.useLazyGetHistoryDetailAPIQuery,
  getDetailAPI: CustomerPortfolio.useLazyGetDetailAPIQuery,
  getGainLossAPI: CustomerPortfolio.useLazyGetGainLossAPIQuery,
  getHoldingAPI: CustomerPortfolio.useLazyGetHoldingAPIQuery,
  getAllocationAPI: CustomerPortfolio.useLazyGetAllocationAPIQuery,
};
