import Box from "components/common/Box";
import React, { useEffect, useState } from "react";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import CustomGrid from "components/datagrid/CustomGrid";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import { uuid } from "helpers/utility";
import { useLocation } from "react-router-dom";
import { CustomerPortfolioApiAction } from "stores/redux/apiSlices/CustomerPortfolio/portfolioApiSlice";
import { useSelector } from "react-redux";


const GainLossTable = ({ customerId, memberID }) => {
  const location = useLocation();
  const select = useSelector((state) => state);
  const user_id = select?.toast?.breadCrumbData?.user_id;
  const cardDataDetail = location.state && location.state.cardData;
  const productId = cardDataDetail?.id;
  const [getPerformance, getDetailTableDetail = []] =
    CustomerPortfolioApiAction.getGainLossAPI();

  const detailTable = getDetailTableDetail?.data?.data ?? [];
  
  const detailTotalCount = getDetailTableDetail?.data?.totalRecords;
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });


  const DetailTableHeader = [ 
    {
      field: "stock_name",
      headerName: "Stock Name",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#242424"
              fontWeight="500"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.stock_name}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "latest_price",
      headerName: "Current value",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.latest_price
                ? `₹ ${Math.abs(params?.row?.latest_price)}`
                : "-"}
            </Text>
          </Stack>
        </>
      ),
    },
    {
        field: "shortTermUnrealized",
        headerName: "Short term unrealized gains",
        flex: 1,
        renderCell: (params) => (
          <>
            {params?.row?.["shortTermUnrealized"] !== undefined &&
              params?.row?.["shortTermUnrealized"] !== 0 &&
              (params?.row?.["shortTermUnrealized"] > 0 ? (
                <Stack direction="row">
                  <NorthIcon
                    style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                  />
                  <Text
                    color="#219653"
                    fontWeight="500"
                    fontSize="14px"
                    alignItems="textCenter"
                  >
                    {params?.row?.["shortTermUnrealized"]
                      ? `${Math.abs(params?.row?.["shortTermUnrealized"])} ₹`
                      : ""}
                  </Text>
                </Stack>
              ) : (
                <Stack direction="row">
                  <SouthIcon
                    style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                  />
                  <Text
                    color="#EB5757"
                    fontWeight="400"
                    fontSize="14px"
                    alignItems="textCenter"
                  >
                    {params?.row?.["shortTermUnrealized"]
                      ? `${Math.abs(params?.row?.["shortTermUnrealized"])} ₹`
                      : ""}
                  </Text>
                </Stack>
              ))}
          </>
        ),
      },
      {
        field: "longTermUnrealized",
        headerName: "Long term unrealized gains",
        flex: 1,
        renderCell: (params) => (
          <>
            {params?.row?.["longTermUnrealized"] !== undefined &&
              params?.row?.["longTermUnrealized"] !== 0 &&
              (params?.row?.["longTermUnrealized"] > 0 ? (
                <Stack direction="row">
                  <NorthIcon
                    style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                  />
                  <Text
                    color="#219653"
                    fontWeight="500"
                    fontSize="14px"
                    alignItems="textCenter"
                  >
                    {params?.row?.["longTermUnrealized"]
                      ? `${Math.abs(params?.row?.["longTermUnrealized"])} ₹`
                      : ""}
                  </Text>
                </Stack>
              ) : (
                <Stack direction="row">
                  <SouthIcon
                    style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                  />
                  <Text
                    color="#EB5757"
                    fontWeight="400"
                    fontSize="14px"
                    alignItems="textCenter"
                  >
                    {" "}
                    {params?.row?.["longTermUnrealized"]
                      ? `${Math.abs(params?.row?.["longTermUnrealized"])} ₹`
                      : ""}
                  </Text>
                </Stack>
              ))}
          </>
        ),
      },
      {
        field: "shortTermRealized",
        headerName: "Short term realized gains",
        flex: 1,
        renderCell: (params) => (
          <>
            {params?.row?.["shortTermRealized"] &&
            params?.row?.["shortTermRealized"] !== 0 ? (
              params?.row?.["shortTermRealized"] > 0 ? (
                <Stack direction="row">
                  <NorthIcon
                    style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                  />
                  <Text
                    color="#219653"
                    fontWeight="500"
                    fontSize="14px"
                    alignItems="textCenter"
                  >
                    {params?.row?.["shortTermRealized"]
                      ? ` ${Math.abs(params?.row?.["shortTermRealized"])} ₹`
                      : ""}
                  </Text>
                </Stack>
              ) : (
                <Stack direction="row">
                  <SouthIcon
                    style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                  />
                  <Text
                    color="#EB5757"
                    fontWeight="400"
                    fontSize="14px"
                    alignItems="textCenter"
                  >
                    {params?.row?.["shortTermRealized"]
                      ? ` ${Math.abs(params?.row?.["shortTermRealized"])} ₹`
                      : ""}
                  </Text>
                </Stack>
              )
            ) : null}
          </>
        ),
      },
      {
        field: "longTermRealized",
        headerName: "Long term realized gains",
        flex: 1,
        renderCell: (params) => (
          <>
            {params?.row?.longTermRealized &&
            params?.row?.longTermRealized !== 0 ? (
              params?.row?.longTermRealized > 0 ? (
                <Stack direction="row">
                  <NorthIcon
                    style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                  />
                  <Text
                    color="#219653"
                    fontWeight="500"
                    fontSize="14px"
                    alignItems="textCenter"
                  >
                    {params?.row?.longTermRealized
                      ? ` ${Math.abs(params?.row?.longTermRealized)} ₹`
                      : ""}
                  </Text>
                </Stack>
              ) : (
                <Stack direction="row">
                  <SouthIcon
                    style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                  />
                  <Text
                    color="#EB5757"
                    fontWeight="400"
                    fontSize="14px"
                    alignItems="textCenter"
                  >
                    {params?.row?.longTermRealized
                      ? ` ${Math.abs(params?.row?.longTermRealized)} ₹`
                      : ""}
                  </Text>
                </Stack>
              )
            ) : null}
          </>
        ),
      },

      {
        field: "dividends",
        headerName: "Dividends",
        flex: 1,
        renderCell: (params) => (
          <>
            <Stack direction="column">
              <Text
                color="#676C76"
                fontWeight="400"
                fontSize="14px"
                alignItems="textCenter"
              >
                {params?.row?.dividends
                  ? `₹ ${Math.abs(params?.row?.dividends)}`
                  : "-"}
              </Text>
            </Stack>
          </>
        ),
      },
  ];

  const AlgoDetailTableHeader = [
    {
      field: "stock_name",
      headerName: "Stock Name",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#242424"
              fontWeight="500"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.stock_name}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "last_price",
      headerName: "Current Price",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.last_price
                ? `₹ ${Math.abs(params?.row?.last_price)}`
                : ""}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "52WeeksHighestHighPrice",
      headerName: "52 week high",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.["52WeeksHighestHighPrice"]
                ? `₹ ${Math.abs(params?.row?.["52WeeksHighestHighPrice"])}`
                : ""}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "52WeeksLowestLowPrice",
      headerName: "52 week loss",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.["52WeeksHighestHighPrice"]
                ? `₹ ${Math.abs(params?.row?.["52WeeksHighestHighPrice"])}`
                : ""}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "1YearReturns",
      headerName: "1 Y return",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.["1YearReturns"] !== undefined &&
            params?.row?.["1YearReturns"] !== 0 &&
            (params?.row?.["1YearReturns"] > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.["1YearReturns"]
                    ? `${Math.abs(params?.row?.["1YearReturns"])} %`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.["1YearReturns"]
                    ? `${Math.abs(params?.row?.["1YearReturns"])} %`
                    : ""}
                </Text>
              </Stack>
            ))}
        </>
      ),
    },
    {
      field: "3YearsReturns",
      headerName: "3 Y return",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.["3YearsReturns"] !== undefined &&
            params?.row?.["3YearsReturns"] !== 0 &&
            (params?.row?.["3YearsReturns"] > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.["3YearsReturns"]
                    ? `${Math.abs(params?.row?.["3YearsReturns"])} %`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {" "}
                  {params?.row?.["3YearsReturns"]
                    ? `${Math.abs(params?.row?.["3YearsReturns"])} %`
                    : ""}
                </Text>
              </Stack>
            ))}
        </>
      ),
    },
    {
      field: "5YearsReturns",
      headerName: "5 Y return",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.["5YearsReturns"] &&
          params?.row?.["5YearsReturns"] !== 0 ? (
            params?.row?.["5YearsReturns"] > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.["5YearsReturns"]
                    ? ` ${Math.abs(params?.row?.["5YearsReturns"])} %`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.["5YearsReturns"]
                    ? ` ${Math.abs(params?.row?.["5YearsReturns"])} %`
                    : ""}
                </Text>
              </Stack>
            )
          ) : null}
        </>
      ),
    },
    {
      field: "return_since_inception",
      headerName: "Returns since inception",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.return_since_inception &&
          params?.row?.return_since_inception !== 0 ? (
            params?.row?.return_since_inception > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.return_since_inception
                    ? ` ${Math.abs(params?.row?.return_since_inception)} %`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.return_since_inception
                    ? ` ${Math.abs(params?.row?.return_since_inception)} %`
                    : ""}
                </Text>
              </Stack>
            )
          ) : null}
        </>
      ),
    },
  ];

  const modifiedDetailTableData = detailTable.map((row) => ({
    ...row,
    id: uuid(),
  }));

  const CustomerHeader = () => {
    return (
      <>
        <Box sx={{ p: 2 }}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Text
              sx={{ color: "primary", mr: 1, fontWeight: 500 }}
              color="primary"
              variant="button"
            >
              Gain and Loss{" "}
            </Text>
          </Stack>
        </Box>
      </>
    );
  };

  const getColumns = (productId) => {
    if (productId === 2) {
      return DetailTableHeader;
    } else if (productId === 3) {
      return DetailTableHeader;
    } else if (productId === 1) {
      return DetailTableHeader;
    } else {
      return;
    }
  };

  React.useEffect(() => {
    getPerformance({
      user_id: user_id,
      page: paginationModel?.page + 1,
      pageSize: paginationModel?.pageSize,
      product_id : productId,
    });
  }, [paginationModel.page, paginationModel.pageSize]);

  const handlePaginationChange = (newPaginationModel) => {
    setPaginationModel((prevPaginationModel) => ({
      ...prevPaginationModel,
      page: newPaginationModel?.page,
      pageSize: newPaginationModel.pageSize,
    }));
  };

  return (
    <div>
      <Stack>
        <CustomGrid
          autoHeight
          list={modifiedDetailTableData}
          getRowId={(row) => row.id}
          columnHeaderHeight={46}
          rowHeight={72}
          isLoading={false}
          columns={getColumns(productId)}
          header={CustomerHeader}
          rowCount={detailTotalCount}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={handlePaginationChange}
        />
      </Stack>
    </div>
  );
};

export default GainLossTable;
