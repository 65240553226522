import React, { useState } from "react";
import Text from "components/common/Text";
import TextField from "components/common/TextField";
import { styled } from "@mui/material";
import DatePicker from "components/common/DatePicker";
import FileUpload from "components/common/FileUpload";
import Radio from "components/common/Radio";
import RadioGroup from "components/common/RadioGroup";
import { FormControlLabel } from "@mui/material";
import Box from "components/common/Box";
import Checkbox from "components/common/Checkbox";

const RiaText = styled(Text)(() => ({
  marginTop: "24px",
  marginBottom: "6px",
  fontSize: "14px",
  fontWeight: 500,
  color: "#242424",
}));

const AddRia = ({
  handleChange,
  formData,
  formError,
  setFormData,
  userType,
  setUserType,
}) => {
  const handleSubbrokerOptionChange = (value) => {
    setFormData({
      ...formData,
      subbrokerOption: value,
    });
  };

  const handleCheckboxChange = (value) => {
    setFormData({
      ...formData,
      algo: value,
    });
  };

  const handleCertificateOptionChange = (key) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: !prevState[key], // Toggle the boolean value for the given key
    }));
  };

  return (
    <>
      <RadioGroup row>
        <FormControlLabel
          value="ria"
          control={<Radio />}
          label="RIA"
          checked={userType === "ria"}
          onChange={() => {
            setUserType("ria");
            handleChange({
              name: "userType",
              value: "ria",
            });
          }}
          sx={{
            "& .MuiFormControlLabel-label": { marginLeft: "8px" },
            mr: 4,
          }}
        />
        <FormControlLabel
          value="subbroker"
          control={<Radio />}
          label="Subbroker"
          checked={userType === "subbroker"}
          onChange={() => {
            setUserType("subbroker");
            handleChange({
              name: "userType",
              value: "subbroker",
            });
          }}
          sx={{ "& .MuiFormControlLabel-label": { marginLeft: "8px" } }}
        />
      </RadioGroup>

      {userType && (
        <>
          {userType === "ria" && (
            <>
              <RiaText>
                {/* {userType === "ria" ? "RIA name" : "Subbroker name"}
                 */}
                RIA Name <span style={{ color: "red" }}>*</span>
              </RiaText>
              <TextField
                placeholder="Enter name"
                name="riaName1"
                value={formData.riaName1}
                onChange={(e) => {
                  handleChange({
                    name: e.target.name,
                    value: e.target.value,
                  });
                }}
              />
              {formError.riaName1 && (
                <Text variant="small" color="red" py={1}>
                  {formError.riaName1}
                </Text>
              )}

              <RiaText>
                Email address <span style={{ color: "red" }}>*</span>
              </RiaText>
              <TextField
                placeholder="Enter email address"
                name="emailAddress"
                value={formData.emailAddress}
                onChange={(e) => {
                  handleChange({
                    name: e.target.name,
                    value: e.target.value,
                  });
                }}
              />
              {formError.emailAddress && (
                <Text variant="small" color="red" py={1}>
                  {formError.emailAddress}
                </Text>
              )}

              <RiaText>
                Phone number <span style={{ color: "red" }}>*</span>
              </RiaText>
              <TextField
                placeholder="Enter phone number"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={(e) => {
                  // Validate input using regex to allow only 10 digits
                  const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                  const trimmedValue = numericValue.slice(0, 10); // Limit to 10 digits
                  handleChange({
                    name: e.target.name,
                    value: trimmedValue,
                  });
                }}
              />
              {formError.phoneNumber && (
                <Text variant="small" color="red" py={1}>
                  {formError.phoneNumber}
                </Text>
              )}
              <RiaText>
                SEBI Registration Number <span style={{ color: "red" }}>*</span>
              </RiaText>
              <TextField
                placeholder="Enter SEBI Registration Number"
                name="sebiRegistrationNumber"
                value={formData.sebiRegistrationNumber}
                onChange={(e) => {
                  // Validate input to allow only alphanumeric characters and limit to 16 characters
                  const alphanumericValue = e.target.value.replace(
                    /[^a-zA-Z0-9]/g,
                    ""
                  ); // Remove non-alphanumeric characters
                  const limitedValue = alphanumericValue.slice(0, 16); // Limit to 16 characters
                  handleChange({
                    name: e.target.name,
                    value: limitedValue,
                  });
                }}
              />

              {formError.sebiRegistrationNumber && (
                <Text variant="small" color="red" py={1}>
                  {formError.sebiRegistrationNumber}
                </Text>
              )}

              <RiaText>
                SEBI Expiry Date <span style={{ color: "red" }}>*</span>
              </RiaText>
              <DatePicker
                label="Select Date"
                value={formData.sebiExpiryDate}
                onChange={(d) => {
                  handleChange({ name: "sebiExpiryDate", value: d["$d"] });
                }}
              />
              {formError.sebiExpiryDate && (
                <Text variant="small" color="red" py={1}>
                  {formError.sebiExpiryDate}
                </Text>
              )}

              <RiaText>
                BASL Number <span style={{ color: "red" }}>*</span>
              </RiaText>
              <TextField
                placeholder="Enter BASL Number"
                name="baslNumber"
                value={formData.baslNumber}
                onChange={(e) => {
                  // Validate input to allow only alphanumeric characters and limit to 16 characters
                  const alphanumericValue = e.target.value.replace(
                    /[^a-zA-Z0-9]/g,
                    ""
                  );
                  const limitedValue = alphanumericValue.slice(0, 16); // Limit to 16 characters
                  handleChange({
                    name: e.target.name,
                    value: limitedValue,
                  });
                }}
              />

              {formError.baslNumber && (
                <Text variant="small" color="red" py={1}>
                  {formError.baslNumber}
                </Text>
              )}

              <RiaText>
                BASL Expiry Date <span style={{ color: "red" }}>*</span>
              </RiaText>
              <DatePicker
                label="Select Date"
                value={formData.expiryDate}
                onChange={(d) => {
                  handleChange({ name: "expiryDate", value: d["$d"] });
                }}
              />
              {formError.expiryDate && (
                <Text variant="small" color="red" py={1}>
                  {formError.expiryDate}
                </Text>
              )}
              <RiaText>
                PORTAL Expiry Date <span style={{ color: "red" }}>*</span>
              </RiaText>
              <DatePicker
                label="Select Date"
                value={formData.portalExpiryDate}
                onChange={(d) => {
                  handleChange({ name: "portalExpiryDate", value: d["$d"] });
                }}
              />
              {formError.portalExpiryDate && (
                <Text variant="small" color="red" py={1}>
                  {formError.portalExpiryDate}
                </Text>
              )}

              <RiaText>
                SEBI Registration{" "}
                <span style={{ fontSize: "12px" }}>
                  (PDF and Image Format Only){" "}
                  <span style={{ color: "red" }}>*</span>
                </span>{" "}
              </RiaText>
              <FileUpload
                name="sebiRegistration"
                handleChange={(d) => {
                  handleChange(d);
                }}
                acceptedFileTypes={[
                  "application/pdf",
                  "image/jpeg",
                  "image/jpg",
                  "image/png",
                ]}
              />
              {formError.sebiRegistration && (
                <Text variant="small" color="red" py={1}>
                  {formError.sebiRegistration}
                </Text>
              )}

              <RiaText>
                BASL membership{" "}
                <span style={{ fontSize: "12px" }}>
                  (PDF and Image Format Only){" "}
                  <span style={{ color: "red" }}>*</span>
                </span>
              </RiaText>
              <FileUpload
                name="baslMembership"
                handleChange={handleChange}
                acceptedFileTypes={[
                  "application/pdf",
                  "image/jpeg",
                  "image/jpg",
                  "image/png",
                ]}
              />
              {formError.baslMembership && (
                <Text variant="small" color="red" py={1}>
                  {formError.baslMembership}
                </Text>
              )}

              <RiaText>
                Investment advisory exam 1{" "}
                <span style={{ fontSize: "12px" }}>
                  (PDF and Image Format Only){" "}
                  <span style={{ color: "red" }}>*</span>
                </span>{" "}
              </RiaText>
              <FileUpload
                name="investmentAdvisoryExam1"
                handleChange={handleChange}
                acceptedFileTypes={[
                  "application/pdf",
                  "image/jpeg",
                  "image/jpg",
                  "image/png",
                ]}
              />
              {formError.investmentAdvisoryExam1 && (
                <Text variant="small" color="red" py={1}>
                  {formError.investmentAdvisoryExam1}
                </Text>
              )}

              <RiaText>
                Investment advisory exam 2{" "}
                <span style={{ fontSize: "12px" }}>
                  (PDF and Image Format Only){" "}
                  <span style={{ color: "red" }}>*</span>
                </span>
              </RiaText>
              <FileUpload
                name="investmentAdvisoryExam2"
                handleChange={handleChange}
                acceptedFileTypes={[
                  "application/pdf",
                  "image/jpeg",
                  "image/jpg",
                  "image/png",
                ]}
              />
              {formError.investmentAdvisoryExam2 && (
                <Text variant="small" color="red" py={1}>
                  {formError.investmentAdvisoryExam2}
                </Text>
              )}
            </>
          )}

          {userType === "subbroker" && (
            <>
              <RiaText>
                Subbroker name <span style={{ color: "red" }}>*</span>
              </RiaText>
              <TextField
                placeholder="Enter subbroker name"
                name="subbrokerName"
                value={formData.subbrokerName}
                onChange={(e) => {
                  handleChange({
                    name: e.target.name,
                    value: e.target.value,
                  });
                }}
              />
              {formError.subbrokerName && (
                <Text variant="small" color="red" py={1}>
                  {formError.subbrokerName}
                </Text>
              )}
              <RiaText>
                Email address <span style={{ color: "red" }}>*</span>
              </RiaText>
              <TextField
                placeholder="Enter email address"
                name="subbrokerEmailAddress"
                value={formData.subbrokerEmailAddress}
                onChange={(e) => {
                  handleChange({
                    name: e.target.name,
                    value: e.target.value,
                  });
                }}
              />
              <RiaText>
                Phone number <span style={{ color: "red" }}>*</span>
              </RiaText>
              <TextField
                placeholder="Enter phone number"
                name="subbrokerPhoneNumber"
                value={formData.subbrokerPhoneNumber}
                onChange={(e) => {
                  // Validate input using regex to allow only 10 digits
                  const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                  const trimmedValue = numericValue.slice(0, 10); // Limit to 10 digits
                  handleChange({
                    name: e.target.name,
                    value: trimmedValue,
                  });
                }}
              />
              <RiaText>
                Aadhar Number <span style={{ color: "red" }}>*</span>
              </RiaText>
              <TextField
                placeholder="Enter Aadhar Number"
                name="subbrokerAadharCard"
                value={formData.subbrokerAadharCard}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                  const trimmedValue = numericValue.slice(0, 12); // Limit to 10 digits
                  handleChange({
                    name: e.target.name,
                    value: trimmedValue,
                  });
                }}
              />
              <RiaText>
                Aadhar Upload{" "}
                <span style={{ fontSize: "12px" }}>
                  (PDF and Image Format Only)
                  <span style={{ color: "red" }}>*</span>
                </span>{" "}
              </RiaText>
              <FileUpload
                name="aadharBase"
                handleChange={(d) => {
                  handleChange(d);
                }}
                acceptedFileTypes={[
                  "application/pdf",
                  "image/jpeg",
                  "image/jpg",
                  "image/png",
                ]}
              />
              <RiaText>
                Pan Number <span style={{ color: "red" }}>*</span>
              </RiaText>
              <TextField
                placeholder="Enter Pan Number"
                name="subbrokerPanCard"
                value={formData.subbrokerPanCard}
                onChange={(e) => {
                  const input = e.target.value.toUpperCase();
                  // Allow only alphanumeric input and limit to 10 characters
                  if (/^[0-9A-Za-z]{0,10}$/.test(input)) {
                    handleChange({
                      name: e.target.name,
                      value: input,
                    });
                  }
                }}
              />
              <RiaText>
                Pan Upload{" "}
                <span style={{ fontSize: "12px" }}>
                  (PDF and Image Format Only)
                  <span style={{ color: "red" }}>*</span>
                </span>{" "}
              </RiaText>
              <FileUpload
                name="panBase"
                handleChange={(d) => {
                  handleChange(d);
                }}
                acceptedFileTypes={[
                  "application/pdf",
                  "image/jpeg",
                  "image/jpg",
                  "image/png",
                ]}
              />

              <div>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    checked={formData.algo}
                    onChange={handleCheckboxChange}
                    name="algo"
                  />
                  <Text
                    sx={{
                      ml: "12px",
                      fontSize: "16px",
                      color: "#242424",
                      fontWeight: 400,
                    }}
                  >
                    Algo
                  </Text>
                </Box>

                {/* Conditionally render the UI based on algo checkbox */}
                {formData.algo && (
                  <>
                    <RiaText>
                      NISM Number <span style={{ color: "red" }}>*</span>
                    </RiaText>
                    <TextField
                      placeholder="Enter NISM Number"
                      name="nismNumber"
                      value={formData.nismNumber}
                      onChange={(e) => {
                        handleChange({
                          name: e.target.name,
                          value: e.target.value,
                        });
                      }}
                    />
                    <RiaText>
                      NISM Certificate{" "}
                      {/* <span style={{ fontSize: "12px" }}>
              (PDF and Image Format Only)
            </span>{" "} */}
                    </RiaText>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          mr: 2,
                        }}
                      >
                        <Radio
                          sx={{ width: "12px", height: "12px", mt: "9px" }}
                          checked={formData.subbrokerOption === "yes"}
                          onChange={() => handleSubbrokerOptionChange("yes")}
                        />
                        <Text
                          sx={{
                            ml: "12px",
                            mt: "9px",
                            fontSize: "16px",
                            color: "#242424",
                            fontWeight: 400,
                          }}
                        >
                          Yes
                        </Text>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Radio
                          sx={{ width: "12px", height: "12px", mt: "9px" }}
                          checked={formData.subbrokerOption === "no"}
                          onChange={() => handleSubbrokerOptionChange("no")}
                        />
                        <Text
                          sx={{
                            ml: "12px",
                            mt: "9px",
                            fontSize: "16px",
                            color: "#242424",
                            fontWeight: 400,
                          }}
                        >
                          No (Upload Employee's NISM Certificate)
                        </Text>
                      </Box>
                    </Box>

                    {formData.subbrokerOption === "yes" && (
                      <>
                        <RiaText>
                          NISM Certificate{" "}
                          <span style={{ color: "red" }}>*</span>
                        </RiaText>
                        <FileUpload
                          name="NismCertificateOwn"
                          handleChange={(d) => {
                            handleChange(d);
                          }}
                          acceptedFileTypes={[
                            "application/pdf",
                            "image/jpeg",
                            "image/jpg",
                            "image/png",
                          ]}
                        />
                      </>
                    )}

                    {formData.subbrokerOption === "no" && (
                      <>
                        <RiaText>
                          Aadhar Number <span style={{ color: "red" }}>*</span>
                        </RiaText>
                        <TextField
                          placeholder="Enter Aadhar Number"
                          name="subbrokerEmployeeAadharCard"
                          value={formData.subbrokerEmployeeAadharCard}
                          onChange={(e) => {
                            const numericValue = e.target.value.replace(
                              /\D/g,
                              ""
                            ); // Remove non-numeric characters
                            const trimmedValue = numericValue.slice(0, 12); // Limit to 12 digits
                            handleChange({
                              name: e.target.name,
                              value: trimmedValue,
                            });
                          }}
                        />

                        <RiaText>
                          Aadhar Card Upload{" "}
                          <span style={{ fontSize: "12px" }}>
                            (PDF and Image Format Only){" "}
                            <span style={{ color: "red" }}>*</span>
                          </span>{" "}
                        </RiaText>
                        <FileUpload
                          name="AadharCard"
                          handleChange={(d) => {
                            handleChange(d);
                          }}
                          acceptedFileTypes={[
                            "application/pdf",
                            "image/jpeg",
                            "image/jpg",
                            "image/png",
                          ]}
                        />

                        <RiaText>
                          Pan Number <span style={{ color: "red" }}>*</span>
                        </RiaText>
                        <TextField
                          placeholder="Enter Pan Number"
                          name="subbrokerEmployeePanCard"
                          value={formData.subbrokerEmployeePanCard}
                          onChange={(e) => {
                            const input = e.target.value.toUpperCase();
                            if (/^[0-9A-Za-z]{0,10}$/.test(input)) {
                              handleChange({
                                name: e.target.name,
                                value: input,
                              });
                            }
                          }}
                        />

                        <RiaText>
                          Pan Upload{" "}
                          <span style={{ fontSize: "12px" }}>
                            (PDF and Image Format Only){" "}
                            <span style={{ color: "red" }}>*</span>
                          </span>{" "}
                        </RiaText>
                        <FileUpload
                          name="PanCard"
                          handleChange={(d) => {
                            handleChange(d);
                          }}
                          acceptedFileTypes={[
                            "application/pdf",
                            "image/jpeg",
                            "image/jpg",
                            "image/png",
                          ]}
                        />

                        <RiaText>
                          NISM number <span style={{ color: "red" }}>*</span>
                        </RiaText>
                        <TextField
                          placeholder="Enter Pan Card Number"
                          name="nismEmployeeNumber"
                          value={formData.nismEmployeeNumber}
                          onChange={(e) => {
                            handleChange({
                              name: e.target.name,
                              value: e.target.value,
                            });
                          }}
                        />

                        <RiaText>
                          NISM Upload{" "}
                          <span style={{ fontSize: "12px" }}>
                            (PDF and Image Format Only){" "}
                            <span style={{ color: "red" }}>*</span>
                          </span>{" "}
                        </RiaText>
                        <FileUpload
                          name="NismCertificate"
                          handleChange={(d) => {
                            handleChange(d);
                          }}
                          acceptedFileTypes={[
                            "application/pdf",
                            "image/jpeg",
                            "image/jpg",
                            "image/png",
                          ]}
                        />
                      </>
                    )}
                  </>
                )}
              </div>

              <RiaText>
                Subbroker Certificate Upload{" "}
                <span style={{ fontSize: "12px" }}>
                  (PDF and Image Format Only){" "}
                  <span style={{ color: "red" }}>*</span>
                </span>{" "}
              </RiaText>
              <FileUpload
                name="subBrokerBase"
                handleChange={(d) => {
                  handleChange(d);
                }}
                acceptedFileTypes={[
                  "application/pdf",
                  "image/jpeg",
                  "image/jpg",
                  "image/png",
                ]}
              />

              <RiaText>Select any one option </RiaText>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  sx={{ width: "12px", height: "12px", mt: "9px" }}
                  checked={formData.nseCartificate}
                  onChange={() =>
                    handleCertificateOptionChange("nseCartificate")
                  }
                />

                <Text
                  sx={{
                    ml: "12px",
                    mt: "9px",
                    fontSize: "16px",
                    color: "#242424",
                    fontWeight: 400,
                  }}
                >
                  NSE
                </Text>
              </Box>

              {/* Error message for radio button selection */}
              {/* {!formData.certificateOption && formError.certificateOption && (
                <Text sx={{ color: "red", fontSize: "12px", mt: "5px" }}>
                  {formError.certificateOption}
                </Text>
              )} */}

              {formData.nseCartificate && (
                <>
                  <RiaText>
                    NSE Certificate{" "}
                    <span style={{ fontSize: "12px" }}>
                      (PDF and Image Format Only)
                    </span>{" "}
                  </RiaText>
                  <FileUpload
                    name="nseCertificateBase"
                    handleChange={(d) => {
                      handleChange(d);
                    }}
                    acceptedFileTypes={[
                      "application/pdf",
                      "image/jpeg",
                      "image/jpg",
                      "image/png",
                    ]}
                  />

                  {/* Error message for file upload */}
                  {formError.nseCertificateBase && (
                    <Text sx={{ color: "red", fontSize: "12px", mt: "5px" }}>
                      {formError.nseCertificateBase}
                    </Text>
                  )}
                </>
              )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  sx={{ width: "12px", height: "12px", mt: "9px" }}
                  checked={formData.nseFnoCartificate}
                  onChange={() =>
                    handleCertificateOptionChange("nseFnoCartificate")
                  }
                />

                <Text
                  sx={{
                    ml: "12px",
                    mt: "9px",
                    fontSize: "16px",
                    color: "#242424",
                    fontWeight: 400,
                  }}
                >
                  NSE FNO Certificate
                </Text>
              </Box>
              {/* Error message for Checkbox button selection */}
              {!formData.certificateOption && formError.certificateOption && (
                <Text sx={{ color: "red", fontSize: "12px", mt: "5px" }}>
                  {formError.certificateOption}
                </Text>
              )}
              {formData.nseFnoCartificate && (
                <>
                  <RiaText>
                    NSE FNO Certificate{" "}
                    <span style={{ fontSize: "12px" }}>
                      (PDF and Image Format Only)
                    </span>{" "}
                  </RiaText>
                  <FileUpload
                    name="nseFnoCertificateBase"
                    handleChange={(d) => {
                      handleChange(d);
                    }}
                    acceptedFileTypes={[
                      "application/pdf",
                      "image/jpeg",
                      "image/jpg",
                      "image/png",
                    ]}
                  />

                  {/* Error message for file upload */}
                  {formError.nseFnoCertificateBase && (
                    <Text sx={{ color: "red", fontSize: "12px", mt: "5px" }}>
                      {formError.nseFnoCertificateBase}
                    </Text>
                  )}
                </>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  sx={{ width: "12px", height: "12px", mt: "9px" }}
                  checked={formData.bseCertificate}
                  onChange={() =>
                    handleCertificateOptionChange("bseCertificate")
                  }
                />

                <Text
                  sx={{
                    ml: "12px",
                    mt: "9px",
                    fontSize: "16px",
                    color: "#242424",
                    fontWeight: 400,
                  }}
                >
                  BSE Certificate
                </Text>
              </Box>
              {!formData.certificateOption && formError.certificateOption && (
                <Text sx={{ color: "red", fontSize: "12px", mt: "5px" }}>
                  {formError.certificateOption}
                </Text>
              )}
              {formData.bseCertificate && (
                <>
                  <RiaText>
                    BSE Certificate{" "}
                    <span style={{ fontSize: "12px" }}>
                      (PDF and Image Format Only)
                    </span>{" "}
                  </RiaText>
                  <FileUpload
                    name="bseCertificateBase"
                    handleChange={(d) => {
                      handleChange(d);
                    }}
                    acceptedFileTypes={[
                      "application/pdf",
                      "image/jpeg",
                      "image/jpg",
                      "image/png",
                    ]}
                  />

                  {formError.bseCertificateBase && (
                    <Text sx={{ color: "red", fontSize: "12px", mt: "5px" }}>
                      {formError.bseCertificateBase}
                    </Text>
                  )}
                </>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  sx={{ width: "12px", height: "12px", mt: "9px" }}
                  checked={formData.bseFnoCertificate}
                  onChange={() =>
                    handleCertificateOptionChange("bseFnoCertificate")
                  }
                />
                <Text
                  sx={{
                    ml: "12px",
                    mt: "9px",
                    fontSize: "16px",
                    color: "#242424",
                    fontWeight: 400,
                  }}
                >
                  BSE FNO Certificate
                </Text>
              </Box>
              {!formData.certificateOption && formError.certificateOption && (
                <Text sx={{ color: "red", fontSize: "12px", mt: "5px" }}>
                  {formError.certificateOption}
                </Text>
              )}
              {formData.bseFnoCertificate && (
                <>
                  <RiaText>
                    BSE FNO Certificate{" "}
                    <span style={{ fontSize: "12px" }}>
                      (PDF and Image Format Only)
                    </span>{" "}
                  </RiaText>
                  <FileUpload
                    name="bseFnoCertificateBase"
                    handleChange={(d) => {
                      handleChange(d);
                    }}
                    acceptedFileTypes={[
                      "application/pdf",
                      "image/jpeg",
                      "image/jpg",
                      "image/png",
                    ]}
                  />

                  {formError.bseFnoCertificateBase && (
                    <Text sx={{ color: "red", fontSize: "12px", mt: "5px" }}>
                      {formError.bseFnoCertificateBase}
                    </Text>
                  )}
                </>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  sx={{ width: "12px", height: "12px", mt: "9px" }}
                  checked={formData.ectCertificate}
                  onChange={() =>
                    handleCertificateOptionChange("ectCertificate")
                  }
                />

                <Text
                  sx={{
                    ml: "12px",
                    mt: "9px",
                    fontSize: "16px",
                    color: "#242424",
                    fontWeight: 400,
                  }}
                >
                  ECT Certificate
                </Text>
              </Box>
              {!formData.certificateOption && formError.certificateOption && (
                <Text sx={{ color: "red", fontSize: "12px", mt: "5px" }}>
                  {formError.certificateOption}
                </Text>
              )}
              {formData.ectCertificate && (
                <>
                  <RiaText>
                    ECT Certificate{" "}
                    <span style={{ fontSize: "12px" }}>
                      (PDF and Image Format Only)
                    </span>{" "}
                  </RiaText>
                  <FileUpload
                    name="ectCertificateBase"
                    handleChange={(d) => {
                      handleChange(d);
                    }}
                    acceptedFileTypes={[
                      "application/pdf",
                      "image/jpeg",
                      "image/jpg",
                      "image/png",
                    ]}
                  />
                  {formError.ectCertificateBase && (
                    <Text sx={{ color: "red", fontSize: "12px", mt: "5px" }}>
                      {formError.ectCertificateBase}
                    </Text>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default AddRia;
