import React from "react";
import MTabs from "@mui/material/Tabs";
import MTab from "@mui/material/Tab";
import { styled } from "@mui/system";
import Box from "./Box";

const StyledTabs = styled(MTabs)(({ theme }) => ({
	// Add your custom styles for Tabs here
	borderBottom: `1px solid ${theme.palette.divider}`,
}));

const StyledTab = styled(MTab)(({ theme }) => ({
	// Add your custom styles for each Tab here
	fontSize: theme.typography.pxToRem(14),
	fontWeight: 500,
	opacity: ({ disabled }) => (disabled ? 0.5 : 1), // Adjust opacity for disabled tab
	cursor: ({ disabled }) => (disabled ? "not-allowed" : "pointer"), // Change cursor for disabled tab
}));

const Tabs = (props) => {
	const { tabs, onChange, value, tabSx, ...restProps } = props;

	return (
		<Box sx={{ borderBottom: 1, borderColor: "divider"
		,"& .Mui-selected":{
			borderBottom: "3px solid #142E56"
		},
		}}>
			<StyledTabs
				TabIndicatorProps={{
					style: {
						display: "none"
					},
				}}
				onChange={onChange}
				variant="scrollable"
				scrollButtons="auto"
				allowScrollButtonsMobile
				value={value}
				{...restProps}
			>
				{tabs.map((item, i) => (
					<StyledTab
						key={item.value}
						value={item.value}
						label={item.label}
						disabled={item.disabled} // Pass disabled property
						sx={{ ...tabSx }}
					/>
				))}
			</StyledTabs>
		</Box>
	);
};

export default React.memo(Tabs);
