import React from "react";
import NewDialog from "components/common/Dialog";
import Button from "components/common/Button";
import Box from "components/common/Box";
import TextField from "components/common/TextField";

const SquareOff = ({
  openModal,
  setOpenModal,
  selectedRow,
  ordertypeStack,
  setOrderTypeStack,
  sqareOffApiLoading,
  editableStopLoss,
  setEditableStopLoss,
  editableProductType,
  setEditableProductType,
  editableQuantity,
  setEditableQuantity,
  editablePrice,
  setEditablePrice,
  handleSquareOffOpenModal,
  handleSquareOffCloseModal,
  handleSquareOffSave
}) => {
  return (
    <>
      <NewDialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="confirm-square-off-modal"
        aria-describedby="confirm-square-off-modal-description"
      >
        <Box
        // sx={{
        //   p: 4,
        //   backgroundColor: "white",
        //   width: "400px",
        //   margin: "auto",
        //   marginTop: "100px",
        // }}
        >
          <h2>Edit Trade</h2>
          {selectedRow && (
            <div>
              {/* Display static fields */}
              <p style={{ marginTop: "12px", marginBottom: "20px" }}></p>
              <TextField
                select
                label="Order Type"
                value={ordertypeStack}
                onChange={(e) => setOrderTypeStack(e.target.value)} // Update orderType on change
                fullWidth
                sx={{ marginBottom: 2 }}
                SelectProps={{
                  native: true, // Using native select for simplicity
                }}
              >
                <option value="MARKET">MARKET</option>
                <option value="LIMIT">LIMIT</option>
              </TextField>

              <TextField
                label="Price"
                variant="outlined"
                value={editablePrice}
                onChange={(e) => {
                  const value = e.target.value;
                  // Allow numbers with optional decimal point
                  if (/^\d*\.?\d*$/.test(value)) {
                    setEditablePrice(value);
                  }
                }}
                fullWidth
                sx={{ marginBottom: 2 }}
                disabled={ordertypeStack !== "LIMIT"}
              />

              <TextField
                label="Stop Loss"
                variant="outlined"
                value={editableStopLoss}
                onChange={(e) => {
                  const value = e.target.value;
                  // Only allow numbers
                  if (/^\d*\.?\d*$/.test(value)) {
                    setEditableStopLoss(value);
                  }
                }}
                fullWidth
                sx={{ marginBottom: 2 }}
              />

              <TextField
                select
                label="Product Type"
                value={editableProductType}
                onChange={(e) => setEditableProductType(e.target.value)} // Update orderType on change
                fullWidth
                sx={{ marginBottom: 2 }}
                SelectProps={{
                  native: true, // Using native select for simplicity
                }}
              >
                <option value="CNC">CNC</option>
                <option value="MIS">MIS/Margin</option> {/* Updated value */}
                <option value="NRML">NORMAL</option>
              </TextField>

              {/* Editable quantity */}
              <TextField
                label="Quantity"
                variant="outlined"
                value={editableQuantity}
                onChange={(e) => {
                  const value = e.target.value;
                  // Only allow numbers
                  if (/^\d*$/.test(value)) {
                    setEditableQuantity(value);
                  }
                }}
                fullWidth
                sx={{ marginBottom: 2 }}
              />

              {/* Add more fields as necessary */}
            </div>
          )}
          <Button variant="contained"  sx={{ mr: 2 }} onClick={handleSquareOffCloseModal}>
            Close
          </Button>
          <Button variant="contained" color="primary" onClick={handleSquareOffSave}>
            Save
          </Button>
        </Box>
      </NewDialog>
    </>
  );
};
export default SquareOff;
