import {
  Chip,
  FormControl,
  FormControlLabel,
  Select,
  ListSubheader,
  InputAdornment,
  Tooltip,
  Checkbox as MUICheckbox,
} from "@mui/material";
import Box from "./Box";
import TextField from "./TextField";
import SearchIcon from "@mui/icons-material/Search";
import MenuItem from "./MenuItem";
import Checkbox from "./Checkbox";
import ErrorIcon from "@mui/icons-material/Error"; // Import ErrorIcon
import { uuid } from "helpers/utility";
import { useState, useEffect } from "react"; // Add useEffect to manage internal state

const MultiSelect = (props) => {
  const {
    value, // Current selected values
    onChange, // Function to call on change
    onClose,
    onSearchChange,
    options,
    idKeyName = "id",
    disabledKeyName = "disabled",
    listLoading = false,
  } = props;

  const [selectAllChecked, setSelectAllChecked] = useState(false); // Track select all checkbox state

  // Update the select all checkbox state based on current selected values
  useEffect(() => {
    setSelectAllChecked(value.length === options.length);
  }, [value, options]);

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      // Select all options
      onChange(options);  // Pass the full options array
    } else {
      // Deselect all options
      onChange([]);  // Pass an empty array
    }
    setSelectAllChecked(event.target.checked);
  };

  return (
    <FormControl fullWidth>
      <Select
        multiple
        MenuProps={{
          autoFocus: false,
          PaperProps: { style: { maxHeight: 250, minWidth: 320}},
        }}
        value={value}
        onChange={(event) => {
          const { value: selectedValues } = event.target;
          onChange(selectedValues); // This will be an array
        }}
        onClose={onClose}
        renderValue={(selected = []) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
              overflow: "hidden",
            }}
          >
            {selected.slice(0, 3).map((selectedOption) => (
              <Chip key={uuid()} label={selectedOption.name} />
            ))}
            {selected.length > 3 ? (
              <Chip label={`More +${selected.length - 3}`} />
            ) : null}
          </Box>
        )}
      >
        <ListSubheader sx={{zoom: 0.7,}}>
          <TextField
            autoFocus
            placeholder="Search"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={onSearchChange}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>

        {/* Add Select All Checkbox */}
        <MenuItem 
        sx={{
          zoom: 0.7,
        }}
        >
          <FormControlLabel
            control={
              <MUICheckbox
                checked={selectAllChecked}
                onChange={handleSelectAll}
              />
            }
            label="Select All"
          />
        </MenuItem>

        {listLoading && (!options || !options.length) ? (
          <MenuItem disabled={true} sx={{
            zoom: 0.7,
          }}>
            <Box>Loading...</Box>
          </MenuItem>
        ) : null}

        {options?.map((option, i) => {
          const hasChecked = value.some(
            (item) => item[idKeyName] === option[idKeyName]
          );
          const disabledCheck = option[disabledKeyName] || false;

          return (
            <MenuItem
            key={i}
            value={option}
            disabled={false} // Ensure the MenuItem is never disabled
              sx={{
                zoom: 0.7,
                color: disabledCheck ? "grey" : "inherit", // Change the font color if disabledCheck is true
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hasChecked}
                      sx={{ marginRight: "1px" }}
                      disabled={false} // Ensure the Checkbox is always enabled
                    />
                  }
                  label={option.name}
                  sx={{
                    color: disabledCheck ? "#a6a6a6" : "inherit", // Change the font color if disabledCheck is true
                  }}
                />
                {disabledCheck && (
                  <Tooltip title="Broker Details not found" placement="top">
                    <ErrorIcon
                      sx={{ marginRight: "10px", cursor: "pointer" }}
                    />
                  </Tooltip>
                )}
              </Box>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default MultiSelect;
