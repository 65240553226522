import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Box from "components/common/Box";
import Text from "components/common/Text";
import TextField from "components/common/TextField";
import Button from "components/common/Button";
import Grid from "components/common/Grid";
import Stack from "components/common/Stack";
import SideDrawer from "components/common/SideDrawer";
import AddRia from "components/DashboardAdmin/AddRia";
import CustomGrid from "components/datagrid/CustomGrid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TradeCard from "components/dashboard/TradeCard";
import AddFilters from "components/DashboardAdmin/AddFilters";
import TableDrawer from "components/DashboardAdmin/TableDrawer";
import { fileToBase64 } from "helpers/fileFormat";
import FilterIcon from "asset/icons/FilterIcon";
import Chip from "@mui/material/Chip";
import { Paper, Typography } from "@mui/material";
import { styled } from "@mui/system";
import dayjs from "dayjs";
import { adminDashBoardApiAction } from "stores/redux/apiSlices/admin/adminDashBoardApiSlice";
import debounce from "lodash.debounce";
import { toastActions } from "stores/redux/slices/toastSlice";
import { validationHelper } from "helpers/validation";
import useDebounce from "hooks/Debounce";
import { useSelector } from "react-redux";
import AdminDashBoardHeader from "components/DashboardAdmin/AdminDashBoardHeader";

// Styled Components
const CustomerChip = styled(Chip)(() => ({
  marginLeft: "5px",
  height: "25px",
  width: "100%",
  textAlign: "center",
  marginTop: "3px",
}));

const ActiveChip = styled(Chip)(() => ({
  backgroundColor: "#DEFFEC",
  color: "#219653",
  borderRadius: "152px",
  width: "90px",
  height: "24px",
}));

const InactiveChip = styled(Chip)(() => ({
  backgroundColor: "#FFEDED",
  color: "#EB5757",
  borderRadius: "152px",
  width: "90px",
  height: "24px",
}));

const AdminTableRowNames = styled(Typography)(() => ({
  color: "#242424",
  fontSize: "14px",
  fontWeight: 500,
  cursor: "pointer",
}));

const AddFilterBtn = styled(Button)(() => ({
  fontWeight: 500,
  borderRadius: "8px",
  borderColor: "#D0D5DD",
  padding: "8px",
  marginTop: "16px",
  marginRight: "8px",
}));

const DashboardAdminContainer = (props) => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const [userType, setUserType] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isAddRiaDrawerOpen, setisAddRiaDrawerOpen] = useState(false);
  const [isAddFilterDrawerOpen, setisAddFilterDrawerOpen] = useState(false);
  const [isTableDrawerOpen, setisTableDrawerOpen] = useState(false);
  const [dateRangeResponse, setDateRangeResponse] = useState(false);
  const [riaID, setRiaID] = useState(null);
  const [status, setStatus] = useState(null);
  const [searchValue, setSearchValue] = useState(""); // Add this line to define the search value state
  const [selectedStatus, setSelectedStatus] = useState(""); // Initialize with an empty string
  const [button, setButton] = useState(null);
  const [riaStatus, setRiaStatus] = useState(null);
  const [searchQueryList, setSearchQueryList] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const userDetails = useSelector((state) => state.commonReducer.userDetails); // Taking the data From Store

  const [formData, setFormData] = useState({
    riaName1: "",
    riaName2: "",
    emailAddress: "",
    phoneNumber: "",
    sebiRegistrationNumber: "",
    baslNumber: "",
    expiryDate: null,
    portalExpiryDate :null,
    sebiExpiryDate :null,
    sebiRegistration: {},
    baslMembership: {},
    investmentAdvisoryExam1: {},
    investmentAdvisoryExam2: {},
    userType: "",

    subbrokerName: "",
    subbrokerEmailAddress: "",
    subbrokerPhoneNumber: "",
    subbrokerAadharCard: "",
    subbrokerPanCard: "",
    aadharBase: "",
    panBase: "",
    nismNumber: "",
    subbrokerEmployeePanCard: "",
    subbrokerEmployeeAadharCard: "",
    nismEmployeeNumber: "",
    yes: false,
    no: false,
    nseCartificate: false,
    nseFnoCartificate: false,
    bseCertificate: false,
    bseFnoCertificate: false,
    ectCertificate: false,
    nse: "NSE",
    nse_fno: "NSE_FNO",
    bse: "BSE",
    bse_fno: "BSE_FNO",
    ect: "OTHER",
    NismCertificateOwn: {},
    AadharCard: {},
    PanCard: {},
    NismCertificate: {},
    subBrokerBase: {},

    nseCertificateBase: {},
    nseFnoCertificateBase: {},
    bseCertificateBase: {},
    bseFnoCertificateBase: {},
    ectCertificateBase: {},
  });

  const [formError, setFormError] = useState({
    riaName1: "",
    riaName2: "",
    emailAddress: "",
    phoneNumber: "",
    sebiRegistrationNumber: "",
    baslNumber: "",
    expiryDate: null,
    portalExpiryDate:null,
    sebiExpiryDate:null,
    sebiRegistration: [],
    baslMembership: [],
    investmentAdvisoryExam1: [],
    investmentAdvisoryExam2: [],

    NismCertificateOwn: [],
    AadharCard: [],
    PanCard: [],
    NismCertificate: [],
    subBrokerBase: [],
    nseCertificateBase: [],
    nseFnoCertificateBase: [],
    bseCertificateBase: [],
    bseFnoCertificateBase: [],
    ectCertificateBase: [],
  });

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
  };

  const debouncedSearchQueryList = useDebounce(searchQueryList, 500);
  // CustomGrid table Api Call
  const { data: riaTableData = {}, refetch: refetchRiaTableData } =
    adminDashBoardApiAction.getRiaTable({
      status: selectedStatus,
      page: paginationModel.page + 1,
      take: paginationModel.pageSize,
      searchQueryList: debouncedSearchQueryList,
    });

  const data = riaTableData?.result;

  const [handleSelectDateRange] = adminDashBoardApiAction.selectDateRange(); // DatePicker Api Call
  const [handleAddRiaApi] = adminDashBoardApiAction.addRiaUserApi(); // Add ria api calling (post)

  const [handleAddSubbrokerApi] = adminDashBoardApiAction.addSubbrokerUserApi();
  const [handleRiaStatusApi] = adminDashBoardApiAction.riaUpdateStatus(); // Status update api call

  const [singleRia, singleRiaData] = adminDashBoardApiAction.getSingleRia(); // here we are calling the API
  const singleDetail = singleRiaData;

  useEffect(() => {}, [singleRiaData]);

  useEffect(() => {
    refetchRiaTableData({
      status: selectedStatus,
      page: paginationModel.page + 1,
      take: paginationModel.pageSize,
      searchQueryList: debouncedSearchQueryList,
    });
  }, []);
  useEffect(() => {
    if (riaID !== null) {
      singleRia(riaID);
    }
  }, [riaID]);

  useEffect(() => {
    if (status === "Y") {
      setButton(false);
    } else if (status === "N") {
      setButton(true);
    }
  }, [status]);

  useEffect(() => {
    if (button === true) {
      setRiaStatus("Y");
    }
    if (button === false) {
      setRiaStatus("N");
    }
  }, [button]);

  const debouncedHandleDateRangeChange = debounce(
    async (newDateRange, handleSelectDateRange) => {
      // Update the state with the new date range
      setStartDate(newDateRange[0]);
      setEndDate(newDateRange[1]);

      // Format the dates in the required format for the API payload
      const startDateFormatted = newDateRange[0]
        ? dayjs(newDateRange[0]).format("YYYY-MM-DD")
        : dayjs().subtract(1, "month").format("YYYY-MM-DD");
      const endDateFormatted = newDateRange[1]
        ? dayjs(newDateRange[1]).format("YYYY-MM-DD")
        : dayjs().format("YYYY-MM-DD");

      // Prepare the payload for API call
      const payload = {
        startDate: startDateFormatted,
        endDate: endDateFormatted,
      };

      try {
        // Call your API with the payload
        const response = await handleSelectDateRange(payload);
        setDateRangeResponse(response.data);
      } catch (error) {
        console.error("Error occurred during API call:", error);
      }
    },
    300
  ); // Debounce for 300 milliseconds

  // Use the debounced function as the event handler
  const handleDateRangeChange = useCallback(
    (newDateRange) => {
      debouncedHandleDateRangeChange(newDateRange, handleSelectDateRange);
    },
    [handleSelectDateRange]
  );

  // below is for kpi cards
  const cardData = [
    {
      id: 1,
      name: "Total number of RIA /SubBrokers",
      value: dateRangeResponse.total_ria,
      chart: "/images/ProfitChart.svg",
      display: "column",
      xs: 4,
    },
    {
      id: 2,
      name: "Active RIA /SubBrokers",
      value: dateRangeResponse.active,
      // percentage: `${dashboardData?.totalprofitpercent} %`,
      chart: "/images/ProfitChart.svg",
      display: "flex",
      xs: 4,
    },
    {
      id: 3,
      name: "Inactive RIA /SubBrokers",
      value: dateRangeResponse.inactive,
      // percentage: `${dashboardData?.taxplpercent} %`,
      chart: "/images/ProfitChart.svg",
      xs: 4,
    },
  ];

  const handleAddRiaDrawer = () => {
    setisAddRiaDrawerOpen(!isAddRiaDrawerOpen);
  };
  const handleAddFilterDrawer = () => {
    setisAddFilterDrawerOpen(!isAddFilterDrawerOpen);
  };
  const handleTableDrawer = (value, status) => {
    let riaId1 = value;
    setRiaID(riaId1);
    setStatus(status);
    setisTableDrawerOpen(!isTableDrawerOpen);
  };
  const handleAddRiaDrawerClose = () => {
    setFormData({
      riaName1: "",
      riaName2: "",
      emailAddress: "",
      phoneNumber: "",
      sebiRegistrationNumber: "",
      baslNumber: "",
      expiryDate: null,
      sebiExpiryDate:null,
      portalExpiryDate:null,
      sebiRegistration: {},
      baslMembership: {},
      investmentAdvisoryExam1: {},
      investmentAdvisoryExam2: {},
      userType: "",

      subbrokerName: "",
      subbrokerEmailAddress: "",
      subbrokerPhoneNumber: "",
      subbrokerAadharCard: "",
      subbrokerPanCard: "",
      aadharBase: "",
      panBase: "",
      nismNumber: "",
      subbrokerEmployeePanCard: "",
      subbrokerEmployeeAadharCard: "",
      nismEmployeeNumber: "",
      nse: "NSE",
      nse_fno: "NSE_FNO",
      bse: "BSE",
      bse_fno: "BSE_FNO",
      ect: "OTHER",
      NismCertificateOwn: {},
      AadharCard: {},
      PanCard: {},
      NismCertificate: {},
      subBrokerBase: {},

      nseCertificateBase: {},
      nseFnoCertificateBase: {},
      bseCertificateBase: {},
      bseFnoCertificateBase: {},
      ectCertificateBase: {},
    });

    setFormError({
      riaName1: "",
      riaName2: "",
      emailAddress: "",
      phoneNumber: "",
      sebiRegistrationNumber: "",
      baslNumber: "",
      expiryDate: null,
      portalExpiryDate:null,
      sebiExpiryDate:null,
      sebiRegistration: [],
      baslMembership: [],
      investmentAdvisoryExam1: [],
      investmentAdvisoryExam2: [],
      NismCertificateOwn: [],
      AadharCard: [],
      PanCard: [],
      NismCertificate: [],
      subBrokerBase: [],
      nseCertificateBase: [],
      nseFnoCertificateBase: [],
      bseCertificateBase: [],
      bseFnoCertificateBase: [],
      ectCertificateBase: [],
    });

    setisAddRiaDrawerOpen(false);
  };

  const handleAddFilterDrawerClose = () => {
    setisAddFilterDrawerOpen(false);
  };

  const handleTableDrawerClose = async () => {
    const userId = riaID; // Replace this with your userId value
    // Prepare the payload with status
    const payload = {
      status: riaStatus,
    };
    try {
      // Call the API function with the payload
      const res = await handleRiaStatusApi({ status: payload.status, userId });
      setisTableDrawerOpen(false); // Close the table drawer
      refetchRiaTableData();
    } catch (error) {
      console.error("Error occurred during API call:", error);
    }
  };

  const handleChange = ({ name, value }) => {
    let newFormData = { ...formData };
    newFormData[name] = value;

    // Ensure userType is updated correctly
    if (name === "userType") {
      setUserType(value);
    }

    setFormData(newFormData);
  };

  const handleSearchInputChangeList = (event) => {
    setSearchQueryList(event.target.value);
  };

  const handleValidation = () => {
    const newFormError = { ...formError };

    // Validate common fields
    const riaNameValidation = validationHelper.required(formData.riaName1);
    newFormError.riaName1 = riaNameValidation.message;

    const emailValidation = validationHelper.email(formData.emailAddress);
    newFormError.emailAddress = emailValidation.message;

    const phoneValidation = validationHelper.mobile(formData.phoneNumber);
    newFormError.phoneNumber = phoneValidation.message;

    // Initialize validation results for RIA-specific fields
    let sebiRegValidation, baslNoValidation, expiryDateValidation,sebiExpiryDateValidation,portalExpiryDateValidation
    let sebiRegFileValidation,
      baslFileValidation,
      investmentAdvExam1Validation,
      investmentAdvExam2Validation;

    if (userType === "ria") {
      // RIA-specific validations
      sebiRegValidation = validationHelper.required(
        formData.sebiRegistrationNumber
      );
      newFormError.sebiRegistrationNumber = sebiRegValidation.message;

      baslNoValidation = validationHelper.required(formData.baslNumber);
      newFormError.baslNumber = baslNoValidation.message;

      expiryDateValidation = validationHelper.required(formData.expiryDate);
      newFormError.expiryDate = expiryDateValidation.message;

      portalExpiryDateValidation = validationHelper.required(formData.portalExpiryDate);
      newFormError.portalExpiryDate = portalExpiryDateValidation.message;

      sebiExpiryDateValidation = validationHelper.required(formData.sebiExpiryDate);
      newFormError.sebiExpiryDate = sebiExpiryDateValidation.message;

      sebiRegFileValidation = formData?.sebiRegistration?.length > 0;
      newFormError.sebiRegistration = sebiRegFileValidation
        ? ""
        : "This field is required";

      baslFileValidation = formData?.baslMembership?.length > 0;
      newFormError.baslMembership = baslFileValidation
        ? ""
        : "This field is required";

      investmentAdvExam1Validation =
        formData?.investmentAdvisoryExam1?.length > 0;
      newFormError.investmentAdvisoryExam1 = investmentAdvExam1Validation
        ? ""
        : "This field is required";

      investmentAdvExam2Validation =
        formData?.investmentAdvisoryExam2?.length > 0;
      newFormError.investmentAdvisoryExam2 = investmentAdvExam2Validation
        ? ""
        : "This field is required";
    } else {
      // Clear errors for RIA-specific fields if userType is not "ria"
      newFormError.riaName1 = "";
      newFormError.emailAddress = "";
      newFormError.phoneNumber = "";

      newFormError.sebiRegistrationNumber = "";
      newFormError.baslNumber = "";
      newFormError.expiryDate = "";
      newFormError.portalExpiryDate = "";
      newFormError.sebiExpiryDate = "";
      newFormError.sebiRegistration = "";
      newFormError.baslMembership = "";
      newFormError.investmentAdvisoryExam1 = "";
      newFormError.investmentAdvisoryExam2 = "";
    }

    // New validations for certificate selection and file uploads
    const certificateOptionValidation = formData.certificateOption !== "";
    newFormError.certificateOption = certificateOptionValidation
      ? ""
      : "Please select a certificate option.";

    let fileUploadValidation = true;

    // Validate the file upload based on the selected certificate option
    if (formData.certificateOption === "nse") {
      fileUploadValidation = formData.nseCertificateBase?.length > 0;
      newFormError.nseCertificateBase = fileUploadValidation
        ? ""
        : "Please upload NSE certificate.";
    } else if (formData.certificateOption === "nsefno") {
      fileUploadValidation = formData.nseFnoCertificateBase?.length > 0;
      newFormError.nseFnoCertificateBase = fileUploadValidation
        ? ""
        : "Please upload NSE FNO certificate.";
    } else if (formData.certificateOption === "bse") {
      fileUploadValidation = formData.bseCertificateBase?.length > 0;
      newFormError.bseCertificateBase = fileUploadValidation
        ? ""
        : "Please upload BSE certificate.";
    } else if (formData.certificateOption === "bsefno") {
      fileUploadValidation = formData.bseFnoCertificateBase?.length > 0;
      newFormError.bseFnoCertificateBase = fileUploadValidation
        ? ""
        : "Please upload BSE FNO certificate.";
    } else if (formData.certificateOption === "ect") {
      fileUploadValidation = formData.ectCertificateBase?.length > 0;
      newFormError.ectCertificateBase = fileUploadValidation
        ? ""
        : "Please upload ECT certificate.";
    }

    setFormError(newFormError);

    // Return true if no errors are present for all fields
    return (
      riaNameValidation.isValid &&
      emailValidation.isValid &&
      phoneValidation.isValid &&
      certificateOptionValidation &&
      fileUploadValidation &&
      (userType === "ria"
        ? sebiRegValidation.isValid &&
          baslNoValidation.isValid &&
          expiryDateValidation.isValid &&
          sebiExpiryDateValidation.isValid &&
          portalExpiryDateValidation.isValid &&
          sebiRegFileValidation &&
          baslFileValidation &&
          investmentAdvExam1Validation &&
          investmentAdvExam2Validation
        : true)
    );
  };

  const handleSubmit = async (e) => {
    if (userType == "ria") {
      e.preventDefault();

      if (!handleValidation()) {
        return;
      }

      try {
        let sebiRegFileBase64,
          baslMembershipFileBase64,
          investmentAdvisoryExam1FileBase64,
          investmentAdvisoryExam2FileBase64;

        // Convert files to base64 only if userType is "ria"
        if (userType === "ria") {
          sebiRegFileBase64 = await fileToBase64(formData.sebiRegistration[0]);
          baslMembershipFileBase64 = await fileToBase64(
            formData.baslMembership[0]
          );
          investmentAdvisoryExam1FileBase64 = await fileToBase64(
            formData.investmentAdvisoryExam1[0]
          );
          investmentAdvisoryExam2FileBase64 = await fileToBase64(
            formData.investmentAdvisoryExam2[0]
          );
        }

        const payload = {
          name: formData?.riaName1,
          email: formData?.emailAddress,
          mobile: parseInt(formData?.phoneNumber),
          sebi_reg_no: formData?.sebiRegistrationNumber,
          basl_no: formData?.baslNumber,
          user_type: formData?.userType,
          basl_expiry: formData?.expiryDate
            ? dayjs(formData?.expiryDate)?.format("YYYY-MM-DD")
            : null,
            portal_expiry_date: formData?.portalExpiryDate
            ? dayjs(formData?.portalExpiryDate)?.format("YYYY-MM-DD")
            : null,
            sebi_expiry: formData?.sebiExpiryDate
            ? dayjs(formData?.sebiExpiryDate)?.format("YYYY-MM-DD")
            : null,
          files:
            userType === "ria"
              ? [
                  {
                    sebi_reg_file: `data:application/pdf;base64,${sebiRegFileBase64}`,
                    basl_membership_file: `data:application/pdf;base64,${baslMembershipFileBase64}`,
                    investment_advisory_exam1_file: `data:application/pdf;base64,${investmentAdvisoryExam1FileBase64}`,
                    investment_advisory_exam2_file: `data:application/pdf;base64,${investmentAdvisoryExam2FileBase64}`,
                  },
                ]
              : [],
        };

        const response = await handleAddRiaApi(payload);

        if (response.error?.data?.status !== 0) {
          refetchRiaTableData();
          handleAddRiaDrawerClose();
        }

        dispatch(
          toastActions.setToastData({
            message: response.error?.data?.message || response.data.message,
          })
        );
      } catch (error) {
        console.error("Error occurred during API call:", error);
      }
    }
    if (userType == "subbroker") {
      e.preventDefault();

      try {
        let AadharBase64,
          PanBase64,
          NismCertificateOwnBase64,
          AadharCardBase64,
          PanCardBase64,
          NismCertificateBase64,
          SubBrokerBase64,
          NseCertificateBase64,
          NseFnoCertificateBase64,
          BseCertificateBase64,
          BseFnoCertificateBase64,
          EctCertificateBase64;

        // Convert files to base64 only if they are selected
        if (userType === "subbroker") {
          if (formData.aadharBase && formData.aadharBase[0]) {
            AadharBase64 = await fileToBase64(formData.aadharBase[0]);
          }
          if (formData.panBase && formData.panBase[0]) {
            PanBase64 = await fileToBase64(formData.panBase[0]);
          }
          if (formData.NismCertificateOwn && formData.NismCertificateOwn[0]) {
            NismCertificateOwnBase64 = await fileToBase64(
              formData.NismCertificateOwn[0]
            );
          }
          if (formData.AadharCard && formData.AadharCard[0]) {
            AadharCardBase64 = await fileToBase64(formData.AadharCard[0]);
          }
          if (formData.PanCard && formData.PanCard[0]) {
            PanCardBase64 = await fileToBase64(formData.PanCard[0]);
          }
          if (formData.NismCertificate && formData.NismCertificate[0]) {
            NismCertificateBase64 = await fileToBase64(
              formData.NismCertificate[0]
            );
          }
          if (formData.subBrokerBase && formData.subBrokerBase[0]) {
            SubBrokerBase64 = await fileToBase64(formData.subBrokerBase[0]);
          }
          if (formData.nseCertificateBase && formData.nseCertificateBase[0]) {
            NseCertificateBase64 = await fileToBase64(
              formData.nseCertificateBase[0]
            );
          }
          if (
            formData.nseFnoCertificateBase &&
            formData.nseFnoCertificateBase[0]
          ) {
            NseFnoCertificateBase64 = await fileToBase64(
              formData.nseFnoCertificateBase[0]
            );
          }
          if (formData.bseCertificateBase && formData.bseCertificateBase[0]) {
            BseCertificateBase64 = await fileToBase64(
              formData.bseCertificateBase[0]
            );
          }
          if (
            formData.bseFnoCertificateBase &&
            formData.bseFnoCertificateBase[0]
          ) {
            BseFnoCertificateBase64 = await fileToBase64(
              formData.bseFnoCertificateBase[0]
            );
          }
          if (formData.ectCertificateBase && formData.ectCertificateBase[0]) {
            EctCertificateBase64 = await fileToBase64(
              formData.ectCertificateBase[0]
            );
          }
        }

        // Determine employee status based on subbrokerOption
        const employeeStatus =
          formData.subbrokerOption === "yes"
            ? 0
            : formData.subbrokerOption === "no"
            ? 1
            : 0; 

        const payload = {
          user_type: "SubBroker", //subbroker
          sub_broker_data: [
            {
              sub_broker_details: {
                status: employeeStatus,
                name: formData?.subbrokerName,
                email: formData?.subbrokerEmailAddress,
                mobile: formData?.subbrokerPhoneNumber,
                user_type: formData?.userType,
                aadhar_card: formData?.subbrokerAadharCard,
                pan_card: formData?.subbrokerPanCard,

                aadhar_card_file: AadharBase64
                  ? `data:application/pdf;base64,${AadharBase64}`
                  : undefined,
                pan_card_file: PanBase64
                  ? `data:application/pdf;base64,${PanBase64}`
                  : undefined,
                nism_number: formData?.nismNumber,
                nism_certificate_vii_file: NismCertificateOwnBase64
                  ? `data:application/pdf;base64,${NismCertificateOwnBase64}`
                  : undefined,

                employee_details: {
                  status: employeeStatus, // Set status dynamically based on choice
                  pan_card: formData?.subbrokerEmployeePanCard,
                  aadhar_card: formData?.subbrokerEmployeeAadharCard,
                  nism_number: formData?.nismEmployeeNumber,
                  aadhar_card_file: AadharCardBase64
                    ? `data:application/pdf;base64,${AadharCardBase64}`
                    : undefined,
                  pan_card_file: PanCardBase64
                    ? `data:application/pdf;base64,${PanCardBase64}`
                    : undefined,
                  nism_certificate_vii_file: NismCertificateBase64
                    ? `data:application/pdf;base64,${NismCertificateBase64}`
                    : undefined,
                },
              },
            },
            {
              sub_broker_certification: {
                sub_broker_certification_file: SubBrokerBase64
                  ? `data:application/pdf;base64,${SubBrokerBase64}`
                  : undefined,
              },
            },

            {
              sub_broker_authorization: {
                nse: formData?.nse,
                nse_fno: formData?.nse_fno,
                bse: formData?.bse,
                bse_fno: formData?.bse_fno,
                ect: formData?.ect,
                nse_certificate: NseCertificateBase64
                  ? `data:application/pdf;base64,${NseCertificateBase64}`
                  : undefined,
                nse_fno_certificate: NseFnoCertificateBase64
                  ? `data:application/pdf;base64,${NseFnoCertificateBase64}`
                  : undefined,
                bse_certificate: BseCertificateBase64
                  ? `data:application/pdf;base64,${BseCertificateBase64}`
                  : undefined,
                bse_fno_certificate: BseFnoCertificateBase64
                  ? `data:application/pdf;base64,${BseFnoCertificateBase64}`
                  : undefined,
                ect_certificate: EctCertificateBase64
                  ? `data:application/pdf;base64,${EctCertificateBase64}`
                  : undefined,
              },
            },
          ],
        };

        const response = await handleAddSubbrokerApi(payload);

        if (response.error?.data?.status !== 0) {
          refetchRiaTableData();
          handleAddRiaDrawerClose();
        }

        dispatch(
          toastActions.setToastData({
            message: response.error?.data?.message || response.data.message,
          })
        );
      } catch (error) {
        console.error("Error occurred during API call:", error);
      }
    }
  };

  const handleAddFilter = () => {
    setisAddFilterDrawerOpen(false);
  };

  const handleRia = () => {
    setisTableDrawerOpen(false);
  };

  const customerColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (DashboardAdminTable) => (
        <Box
          onClick={() => {
            handleTableDrawer(
              DashboardAdminTable?.row.user_id,
              DashboardAdminTable?.row.status
            );
          }}
        >
          <AdminTableRowNames>
            {DashboardAdminTable?.row.name}
          </AdminTableRowNames>
        </Box>
      ),
    },
    {
      field: "userType",
      headerName: "User type",
      flex: 1,
      renderCell: (DashboardAdminTable) => (
        <Box>
          <Typography
            sx={{ color: "#676C76", fontSize: "14px", fontWeight: 400 }}
          >
            {DashboardAdminTable?.row.role_name}
          </Typography>
        </Box>
      ),
    },
    {
      field: "emailAddress",
      headerName: "Email address",
      flex: 1,
      renderCell: (DashboardAdminTable) => (
        <Box>
          <Typography
            sx={{ color: "#676C76", fontSize: "14px", fontWeight: 400 }}
          >
            {DashboardAdminTable?.row.email}
          </Typography>
        </Box>
      ),
    },
    {
      field: "phoneNumber",
      headerName: "Phone number",
      flex: 1,
      renderCell: (DashboardAdminTable) => (
        <Box>
          <Typography
            sx={{ color: "#676C76", fontSize: "14px", fontWeight: 400 }}
          >
            {DashboardAdminTable?.row.mobile}
          </Typography>
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (DashboardAdminTable) => (
        <Box>
          {DashboardAdminTable?.row.status === "Y" ? (
            <ActiveChip label="Active" />
          ) : (
            <InactiveChip label="Inactive" />
          )}
        </Box>
      ),
    },
    {
      field: "view",
      headerName: "View",
      flex: 1,
      renderCell: (DashboardAdminTable) => (
        <Box>
          <VisibilityIcon
            style={{ cursor: "pointer", color: "#676C76" }} // Apply styling to the icon
            onClick={() => {
              handleTableDrawer(
                DashboardAdminTable?.row.user_id,
                DashboardAdminTable?.row.status
              );
            }}
          />
        </Box>
      ),
    },
  ];

  //   if (!data?.length) {
  //     return <div>...Loading</div>;
  //   }
  if (!singleRiaData) {
    return <div>...Loading</div>;
  }

  const DashboardAdminTable = Object.values(data ? data : {})?.map(
    (item, index) => ({
      ...item,
      id: index + 1,
    })
  );

  return (
    <>
      <AdminDashBoardHeader
        handleDateRangeChange={handleDateRangeChange}
        userName={userDetails}
      />

      <Grid sx={{ mb: 3 }} container spacing={2}>
        {cardData.map((element, index) => (
          <Grid item xs={element.xs} key={`cards_index_${element?.id}`}>
            <TradeCard data={element} />
          </Grid>
        ))}
      </Grid>

      <Paper sx={{ borderRadius: "16px", mb: 4, mt: 2, height: "auto" }}>
        <Stack flexDirection="row" justifyContent="space-between">
          <Box style={{ padding: "15px" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={1.5}
              sx={{ width: "100%" }}
              alignItems="center"
              style={{ marginTop: "10px" }}
            >
              <Box sx={{ display: "flex" }}>
                <Stack direction="row">
                  <Text fontSize="20px" fontWeight="600" marginRight="5px">
                    RIA's/SUBBROKER's
                  </Text>
                </Stack>
              </Box>
            </Stack>
            <Text fontSize="15px" fontWeight="400" color="#667085">
              Here you can view the list of RIA's/SUBBROKER's and change their
              status as active or inactive.
            </Text>
          </Box>
          <Box style={{ padding: "25px" }}>
            <Button
              sx={{ pt: "10px", pb: "10px", pr: "16px", pl: "16px" }}
              onClick={handleAddRiaDrawer}
            >
              +Add RIA/SubBroker
            </Button>
          </Box>
        </Stack>

        <Box px="15px">
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={1.5}
            sx={{ width: "100%" }}
            alignItems="center"
            style={{ marginTOp: "50px" }}
          >
            <Box style={{ width: "500px", marginTop: "10px" }}>
              <Stack direction="row">
                <TextField
                  sx={{ maxWidth: "500px" }}
                  placeholder="Search"
                  name="search"
                  onChange={handleSearchInputChangeList}
                  value={searchQueryList}
                />
              </Stack>
            </Box>

            <Box>
              <AddFilterBtn
                startIcon={<FilterIcon />}
                variant="outlined"
                onClick={handleAddFilterDrawer}
              >
                Add Filters
              </AddFilterBtn>
            </Box>
          </Stack>
        </Box>
        <Stack sx={{ marginTop: "24px" }}>
          <CustomGrid
            autoHeight
            list={DashboardAdminTable}
            columnHeaderHeight={46}
            rowHeight={60}
            columns={customerColumns}
            rowCount={riaTableData.total_record}
            pagination
            paginationMode="Server"
            paginationModel={paginationModel}
            onPaginationModelChange={(newPaginationModel) => {
              setPaginationModel(newPaginationModel);
            }}
            pageSizeOptions={[5, 10, 15]}
          />
        </Stack>
      </Paper>

      {/* below is Add RIA sideDrawer */}
      <SideDrawer
        open={isAddRiaDrawerOpen}
        closeDrawer={handleAddRiaDrawerClose}
        title="Add User"
        subtitle="Here you can add the RIA's/SUBBROKER's"
        cancelButtonText={"Cancel"}
        submitButtonText={"Add"}
        handleSubmit={handleSubmit}
        drawerWidth={500}
      >
        <AddRia
          handleChange={handleChange}
          formData={formData}
          setFormData={setFormData}
          formError={formError}
          userType={userType}
          setUserType={setUserType}
        />
      </SideDrawer>

      <SideDrawer
        open={isAddFilterDrawerOpen}
        closeDrawer={handleAddFilterDrawerClose}
        title="Add Filters"
        subtitle="See the data in an organized manner by applying filters"
        cancelButtonText={"Clear all"}
        submitButtonText={"Apply"}
        handleSubmit={handleAddFilter}
        drawerWidth={500}
      >
        <AddFilters
          selectedStatus={selectedStatus}
          handleStatusChange={handleStatusChange}
        />
      </SideDrawer>

      <SideDrawer
        open={isTableDrawerOpen}
        closeDrawer={handleRia}
        title={
          singleDetail?.data?.message?.result?.role_name === "SubBroker"
            ? "View Subbroker"
            : "View RIA"
        }
        subtitle={
          singleDetail?.data?.message?.result?.role_name === "SubBroker"
            ? "Here you can view and change status of the SUBBROKER's."
            : "Here you can view and change status of the RIA’s."
        }
        cancelButtonText={"Cancel"}
        handleSubmit={handleTableDrawerClose}
        submitButtonText={button ? "Mark as Active" : "Mark as Inactive"}
        drawerWidth={500}
      >
        <TableDrawer singleRiaData={singleDetail} />
      </SideDrawer>
    </>
  );
};

export default DashboardAdminContainer;
