import React, { useState, useEffect } from "react";
import Box from "components/common/Box";
import CustomGrid from "components/datagrid/CustomGrid";
import Stack from "components/common/Stack";
import Text from "components/common/Text";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import { useSelector } from "react-redux";
import { uuid } from "helpers/utility";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { customersApiAction } from "stores/redux/apiSlices/customers/customersApiSlice";
import { CustomerPortfolioApiAction } from "stores/redux/apiSlices/CustomerPortfolio/portfolioApiSlice";

const CustomerHeader = () => {
  return (
    <>
      <Box sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <Text
            sx={{ color: "primary", mr: 1, fontWeight: 500 }}
            color="primary"
            variant="button"
          >
            History
          </Text>
        </Stack>
      </Box>
    </>
  );
};

const HistoryTable = ({ customerId, blockID, showTableTab }) => {
  const select = useSelector((state) => state);
  const user_id = select?.toast?.breadCrumbData?.user_id;
  const location = useLocation();
  const cardDataDetail = location.state && location.state.cardData;
  const productId = cardDataDetail?.id;
  const [getPerformanceDetailDataAPI, getPerformanceDetail = []] =
   CustomerPortfolioApiAction.getHistoryDetailAPI();
  const summaryLossTableData = getPerformanceDetail?.data?.data?.summary ?? [];
  const summaryTableDataCount =
    getPerformanceDetail?.data?.data?.summaryDataCount;

  const [viewData, setViewData] = useState(null);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const modifiedSummaryTableData = summaryLossTableData.map((row) => ({
    ...row,
    id: uuid(),
  }));

  useEffect(() => {}, [blockID]);

  const handleCellClick = (row) => {
    setViewData(row);
  };

  const getColumns = (productId) => {
    if (productId === 2) {
      return SummaryModelHeader;
    } else if (productId === 3) {
      return SummaryAlgoHeader;
    } else if (productId === 1) {
      return SummaryCustomHeader;
    } else {
      return;
    }
  };

  useEffect(() => {
    if (
      productId &&
      customerId &&
      paginationModel.page !== undefined &&
      paginationModel.pageSize !== undefined
    ) {
      getPerformanceDetailDataAPI({
        product_id: productId,
        user_id: customerId,
        page: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
      });
    }
  }, [productId, customerId, paginationModel.page, paginationModel.pageSize]);

  const handlePaginationChange = (newPaginationModel) => {
    setPaginationModel({
      ...paginationModel,
      page: newPaginationModel.page,
      pageSize: newPaginationModel.pageSize,
    });
  };

  const SummaryModelHeader = [
    { field: "id", headerName: "id", flex: 1 },
    {
      field: "stock_name",
      headerName: "Stock name",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#242424"
              fontWeight="500"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.stock_name}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "buy_date",
      headerName: "Buy Date",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.buy_date}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "sell_date",
      headerName: "Sell Date ",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.sell_date ? params?.row?.sell_date : "-"}
            </Text>
          </Stack>
        </>
      ),
    },

    {
      field: "exchange",
      headerName: "Index",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#242424"
              fontWeight="500"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.exchange}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.quantity}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "amount_invested",
      renderHeader: () => (
        <Box sx={{ textAlign: "center", lineHeight: 1.2 }}>
          Amount <br /> Invested
        </Box>
      ),
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.amount_invested
                ? `₹ ${Math.abs(params?.row?.amount_invested)}`
                : ""}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "trade_expire_date",
      headerName: "Time",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack
            direction="column"
            title={params.value}
            style={{ cursor: "pointer" }}
          >
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.trade_expire_date}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "gains",
      headerName: "Total gains",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.gains !== undefined && params?.row?.gains !== 0 ? (
            params?.row?.gains > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.gains
                    ? `₹ ${Math.abs(params?.row?.gains)}`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.gains
                    ? `₹ ${Math.abs(params?.row?.gains)}`
                    : ""}
                </Text>
              </Stack>
            )
          ) : null}
        </>
      ),
    },
    {
      field: "amount_after_gains",
      headerName: "Current Value",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.amount_after_gains
                ? `₹ ${Math.abs(params?.row?.amount_after_gains)}`
                : ""}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "todays_change",
      headerName: "Today's change",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.todays_change !== undefined &&
            params?.row?.todays_change !== 0 &&
            (params?.row?.todays_change > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {`${Math.abs(params?.row?.todays_change)} %`}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {`${Math.abs(params?.row?.todays_change)} %`}
                </Text>
              </Stack>
            ))}
        </>
      ),
    },
    {
      field: "annualized_returns",
      renderHeader: () => (
        <Box sx={{ textAlign: "center", lineHeight: 1.2 }}>
          Annualized <br /> Returns
        </Box>
      ),
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.annualized_returns !== undefined &&
          params?.row?.annualized_returns !== 0 ? (
            params?.row?.annualized_returns > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.annualized_returns
                    ? `${Math.abs(params?.row?.annualized_returns)} %`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.annualized_returns
                    ? `${Math.abs(params?.row?.annualized_returns)} %`
                    : ""}
                </Text>
              </Stack>
            )
          ) : null}
        </>
      ),
    },

    {
      field: "message",
      headerName: "Message",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack
            direction="column"
            title={params.value}
            style={{ cursor: "pointer" }}
          >
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.message}
            </Text>
          </Stack>
        </>
      ),
    },

    {
      field: "tradeStatus",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack
            direction="column"
            title={params.value}
            style={{ cursor: "pointer" }}
          >
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.tradeStatus}
            </Text>
          </Stack>
        </>
      ),
    },
  ];

  const SummaryCustomHeader = [
    { field: "id", headerName: "id", flex: 1 },
    {
      field: "stock_name",
      headerName: "Stock name",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#242424"
              fontWeight="500"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.stock_name}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "buy_date",
      headerName: "Buy Date",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.buy_date}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "sell_date",
      headerName: "Sell Date",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.sell_date ? params?.row?.sell_date : "-"}
            </Text>
          </Stack>
        </>
      ),
    },

    {
      field: "exchange",
      headerName: "Index",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#242424"
              fontWeight="500"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.exchange}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.quantity}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "amount_invested",
      renderHeader: () => (
        <Box sx={{ textAlign: "center", lineHeight: 1.2 }}>
          Amount <br /> Invested
        </Box>
      ),
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.amount_invested
                ? `₹ ${Math.abs(params?.row?.amount_invested)}`
                : ""}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "trade_expire_date",
      headerName: "Time",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack
            direction="column"
            title={params.value}
            style={{ cursor: "pointer" }}
          >
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.trade_expire_date}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "gains",
      headerName: "Total gains",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.gains !== undefined && params?.row?.gains !== 0 ? (
            params?.row?.gains > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.gains
                    ? `₹ ${Math.abs(params?.row?.gains)}`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.gains
                    ? `₹ ${Math.abs(params?.row?.gains)}`
                    : ""}
                </Text>
              </Stack>
            )
          ) : null}
        </>
      ),
    },
    {
      field: "amount_after_gains",
      headerName: "Current Value",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.amount_after_gains
                ? `₹ ${Math.abs(params?.row?.amount_after_gains)}`
                : ""}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "todays_change",
      headerName: "Today's change",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.todays_change !== undefined &&
            params?.row?.todays_change !== 0 &&
            (params?.row?.todays_change > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {`${Math.abs(params?.row?.todays_change)} %`}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {`${Math.abs(params?.row?.todays_change)} %`}
                </Text>
              </Stack>
            ))}
        </>
      ),
    },
    {
      field: "annualized_returns",
      renderHeader: () => (
        <Box sx={{ textAlign: "center", lineHeight: 1.2 }}>
          Annualized <br /> Returns
        </Box>
      ),
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.annualized_returns !== undefined &&
          params?.row?.annualized_returns !== 0 ? (
            params?.row?.annualized_returns > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.annualized_returns
                    ? `${Math.abs(params?.row?.annualized_returns)} %`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.annualized_returns
                    ? `${Math.abs(params?.row?.annualized_returns)} %`
                    : ""}
                </Text>
              </Stack>
            )
          ) : null}
        </>
      ),
    },

    {
      field: "message",
      headerName: "Message",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack
            direction="column"
            title={params.value}
            style={{ cursor: "pointer" }}
          >
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.message}
            </Text>
          </Stack>
        </>
      ),
    },

    {
      field: "tradeStatus",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack
            direction="column"
            title={params.value}
            style={{ cursor: "pointer" }}
          >
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.tradeStatus}
            </Text>
          </Stack>
        </>
      ),
    },
  ];

  const SummaryAlgoHeader = [
    { field: "id", headerName: "id", flex: 1 },
    {
      field: "stock_name",
      headerName: "Stock name",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#242424"
              fontWeight="500"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.stock_name}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "buy_date",
      headerName: "Buy Date",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.buy_date}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "sell_date",
      headerName: "Sell Date",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.sell_date ? params?.row?.sell_date : "-"}
            </Text>
          </Stack>
        </>
      ),
    },

    {
      field: "exchange",
      headerName: "Index",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#242424"
              fontWeight="500"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.exchange}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.quantity}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "amount_invested",
      renderHeader: () => (
        <Box sx={{ textAlign: "center", lineHeight: 1.2 }}>
          Amount <br /> Invested
        </Box>
      ),
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.amount_invested
                ? `₹ ${Math.abs(params?.row?.amount_invested)}`
                : ""}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "trade_expire_date",
      headerName: "Time",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack
            direction="column"
            title={params.value}
            style={{ cursor: "pointer" }}
          >
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.trade_expire_date}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "gains",
      headerName: "Total gains",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.gains !== undefined && params?.row?.gains !== 0 ? (
            params?.row?.gains > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.gains
                    ? `₹ ${Math.abs(params?.row?.gains)}`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.gains
                    ? `₹ ${Math.abs(params?.row?.gains)}`
                    : ""}
                </Text>
              </Stack>
            )
          ) : null}
        </>
      ),
    },
    {
      field: "amount_after_gains",
      headerName: "Current Value",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack direction="column">
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.amount_after_gains
                ? `₹ ${Math.abs(params?.row?.amount_after_gains)}`
                : ""}
            </Text>
          </Stack>
        </>
      ),
    },
    {
      field: "todays_change",
      headerName: "Today's change",
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.todays_change !== undefined &&
            params?.row?.todays_change !== 0 &&
            (params?.row?.todays_change > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {`${Math.abs(params?.row?.todays_change)} %`}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="400"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {`${Math.abs(params?.row?.todays_change)} %`}
                </Text>
              </Stack>
            ))}
        </>
      ),
    },
    {
      field: "annualized_returns",
      renderHeader: () => (
        <Box sx={{ textAlign: "center", lineHeight: 1.2 }}>
          Annualized <br /> Returns
        </Box>
      ),
      flex: 1,
      renderCell: (params) => (
        <>
          {params?.row?.annualized_returns !== undefined &&
          params?.row?.annualized_returns !== 0 ? (
            params?.row?.annualized_returns > 0 ? (
              <Stack direction="row">
                <NorthIcon
                  style={{ height: "15px", color: "#219653", marginTop: "3px" }}
                />
                <Text
                  color="#219653"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.annualized_returns
                    ? `${Math.abs(params?.row?.annualized_returns)} %`
                    : ""}
                </Text>
              </Stack>
            ) : (
              <Stack direction="row">
                <SouthIcon
                  style={{ height: "15px", color: "#EB5757", marginTop: "3px" }}
                />
                <Text
                  color="#EB5757"
                  fontWeight="500"
                  fontSize="14px"
                  alignItems="textCenter"
                >
                  {params?.row?.annualized_returns
                    ? `${Math.abs(params?.row?.annualized_returns)} %`
                    : ""}
                </Text>
              </Stack>
            )
          ) : null}
        </>
      ),
    },

    {
      field: "message",
      headerName: "Message",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack
            direction="column"
            title={params.value}
            style={{ cursor: "pointer" }}
          >
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.message}
            </Text>
          </Stack>
        </>
      ),
    },

    {
      field: "tradeStatus",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <>
          <Stack
            direction="column"
            title={params.value}
            style={{ cursor: "pointer" }}
          >
            <Text
              color="#676C76"
              fontWeight="400"
              fontSize="14px"
              alignItems="textCenter"
            >
              {params?.row?.tradeStatus}
            </Text>
          </Stack>
        </>
      ),
    },
  ];
  return (
    <>
      <Box>
        <CustomGrid
          autoHeight
          list={modifiedSummaryTableData}
          columnHeaderHeight={46}
          getRowId={(row) => row.id}
          rowHeight={72}
          columns={getColumns(productId)}
          onCellClick={handleCellClick}
          rowCount={summaryTableDataCount}
          header={CustomerHeader}
          paginationModel={paginationModel}
          paginationMode="server"
          onPaginationModelChange={handlePaginationChange}
        />
      </Box>
    </>
  );
};

export default HistoryTable;
